import {MenuItem, MenuItemWrapper} from "../../styledComponents/navbar.styles"
import store from '../../redux/reducers/rootReducer'
import { login } from '../../redux/actions/login.action';
import authService from '../../services/auth.service';

export default function OfficeStaff() {
  const handleClick = async (e) => {
    store.dispatch(login(false)); 
    authService.logout();       
  };
  
  return ( 
   <MenuItemWrapper>
   <li>
       <span>
          <MenuItem to='/opdrachtenBeheer'>Opdrachten</MenuItem>
        </span>
    </li>
   <li>
       <span>
          <MenuItem to='/certificatenBeheer'>Certificaten beheer</MenuItem>
        </span>
    </li>
    <li>
        <span>
          <MenuItem to='/nietGeverifieerdeBrandwachten'>Verifieer brandwachten</MenuItem>
        </span>
    </li>
    <li>
        <span>
          <MenuItem to='/registrerengebruikers'>Registreren gebruikers</MenuItem>
        </span>
    </li>
     <li>
      <span>
        <MenuItem  to='/AdminPanel'> Admin </MenuItem>
      </span>
     </li>
    <li>
      <span>
        <MenuItem  onClick={handleClick} to='/'>Uitloggen </MenuItem>
      </span>
    </li>
    <li>
      <span>
        <MenuItem  to='/profile'> Account </MenuItem>
      </span>
    </li>
   </MenuItemWrapper>
  )     
}
