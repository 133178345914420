import styled from 'styled-components' 
import {deleteAdmin} from "../../services/admins.service";
import { useLocation , useNavigate } from 'react-router-dom';
import {
  BlueBackground, Container,
  RowOfElements
} from "../../styledComponents/globalStyles";
import {GrayButton} from "../../styledComponents/button.styles";
import {ErrorMessage} from "../../styledComponents/form.styles";
import {useState} from "react";

const Title= styled.h1`
  color: white;
  display: inline;
`

const EditAdmin = () => {
  const location = useLocation();
  const dataPrevPage = location.state;
  const [canNotRemoveAdmin, setCanNotRemoveAdmin] = useState(null);
  const navigate = useNavigate();

  const doDeleteAdmin =  async () => {
    const response = await deleteAdmin(dataPrevPage.id);
    if(response.data.status === 200)
    {
      navigate('/AdminPanel/Personeel');    
    }     
  }

return (
    <BlueBackground>
      <Container>
        <RowOfElements>
          <Title>
            Naam: {dataPrevPage.firstName + ' ' + dataPrevPage.lastName + ' '}
          </Title>
          <GrayButton style={{float: "right"}}  height={"35px"} width={"30vw"} onClick={() => doDeleteAdmin()}>
            Beheerder verwijderen
          </GrayButton>
        </RowOfElements>
        <ErrorMessage>{canNotRemoveAdmin? canNotRemoveAdmin : null}</ErrorMessage>
      </Container>
    </BlueBackground>
)
}
export default EditAdmin;
