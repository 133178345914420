import styled from 'styled-components'
import { useNavigate } from 'react-router-dom';
import { Container, BlueBackground} from '../../styledComponents/globalStyles';

const Titel = styled.h1`
  color: white;
  font-weight: 600;
  font-size: 40px;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;`
const Box = styled.div`
  margin: 5px;
  padding: 20px;
  min-width: 50%;
  height: 80vh;
  background-color: white;
  border-radius: 10px;
  //overflow: hidden;
  cursor: pointer;
  font-size: 15px;
  
`
const BoxWrapper = styled.div`
  display: flex;
  @media (max-width: 772px) {
    display: block;
  }
`

const ChooseUserRole = () => {  
  const navigate = useNavigate();
    const handleClick = async (event, option) => {
        if(option ===1){
          navigate('/RegistrerenBrandwacht');
        }else{
          navigate('/RegistrerenBedrijfsbeheerder');
        }
        
    } 
  
  return (
    <BlueBackground>
      <Container>
        <Wrapper>
          <Titel>Kies hoe je wilt registreren</Titel>      
        </Wrapper>
        <BoxWrapper>
          <Box onClick={event => handleClick(event, 1)} aria-hidden="true"> 
              <h2>
                  Registreren als brandwacht
              </h2>
              Hieronder een beetje uitleg over de optie met een plaatje 
          </Box>
          <Box onClick={event => handleClick(event, 2)} aria-hidden="true"> 
              <h2>
                  Registreren als bedrijfsbeheerder
              </h2>
              Hieronder een beetje uitleg over de optie met een plaatje
          </Box>
        </BoxWrapper>
      </Container>
    </BlueBackground>
  )
}
export default ChooseUserRole