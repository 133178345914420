import styled, { createGlobalStyle} from 'styled-components' ;
import {Colors} from './colors';
const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: 'futura-pt', sans-serif;
    }
`;
export const Container = styled.div`
	width: 100%;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	padding: 0 50px;
	@media screen and (max-width: 960px) {
		padding: 0 30px;
	}
`;

export const BlueBackground = styled.div`
	padding-top: 20px;
	padding-bottom: 20px;
	min-height: 1000px;
	background-color: ${Colors.JbgBlue};
`

export const WhiteWrapper = styled.div`
  padding: 20px;    
  background-color: white;
  border-radius: 10px;    
`
export const RowOfElements = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CenterDiv = styled.div`
  text-align: center;
`

export const MenuWrapper = styled.div`
    display: flex;
    flex-direction: row;
    @media (max-width: 550px) {
      flex-direction: column;
    }
`
export const MenuBox = styled.div`
  margin: 5px;
  padding: 20px;
  width: 400px;
  height: 200px;
  background-color: white;
  border-radius: 10px;
  cursor: pointer;
  font-size: 30px;  
`
export default GlobalStyle;