import styled from 'styled-components'
import { postAccessToken, getGoogleAuthUrl} from '../../services/googleCalendar.service';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Container, BlueBackground } from '../../styledComponents/globalStyles';
 
const Button = styled.button`     
    width: 100%;
    height: 40px;
    border: none;
    padding: 15px, 20px;
    background-color : grey;
    color: white;
    font-weight: bold;
    font-size: 22px;
    cursor: pointer;
    border-radius: 25px;
    justify-content: center;    
`

const Wrapper = styled.div`     
    padding-top: 20px;
`

export default function AddCalendar() {
    const url = window.location.search;
    const urlParams = new URLSearchParams(url);
    const isGoogleAgendaLinked = useSelector(state => state.isGoogleAgendaLinked.isGoogleAgendaLinked);

    useEffect(() => {         
        if(urlParams.get('code')){
            postToken();
        };
    }, []);
    
    const postToken =  async () => {    
       await postAccessToken(urlParams.get('code'));    
    }  
      
    const LoginWithGoogle =  async () => {    
        const response = await getGoogleAuthUrl();
        if(response.status === 200)
        {
            window.location.replace(response.data);
        }     
    }

  return (
    <BlueBackground>
        <Wrapper>           
            <Container>            
                    {isGoogleAgendaLinked?
                        <h1>Uw Google agenda is al gekoppeld</h1>                        
                    :
                        <Button onClick={LoginWithGoogle}>
                            Inloggen bij Google
                        </Button>                                            
                    }            
            </Container>
        </Wrapper>
    </BlueBackground>        
  )
}