import { BlueBackground, Container } from "../../styledComponents/globalStyles"
import styled from "styled-components"
import { useState, useEffect } from "react"
import { createCertificateType, getCertificateTypes } from "../../services/certificate.service"
import { ErrorMessage } from "../../styledComponents/form.styles"
import { Colors } from "../../styledComponents/colors"

const Wrapper = styled.div`
    padding: 20px;    
    background-color: white;
    border-radius: 10px;    
`
const Form = styled.form`
    display: flex;
    flex-direction: column;
`
const Input = styled.input`
    flex: 1;
    min-width: 40%;
    margin: 10px 0px;
    padding: 10px;
    border-radius: 10px;
`
const Button = styled.button`
    height: 40px;
    border: none;
    padding: 15px, 20px;
    background-color : ${Colors.JbgBlue};
    color: white;
    font-weight: bold;
    font-size: 15px;
    cursor: pointer;
    border-radius: 25px;
    justify-content: center;
    margin-left : 10px;
`

const CreateCertificateType = () => {
    const [certificateName, setCertificateName] = useState('');
    const [showCertificatedCreatedSuccesfully, setShowCertificatedCreatedSuccesfully] = useState(false);
    const [showCertificateAllreadyExists, setShowCertificateAllreadyExists] = useState(false);
    const [certificateTypes, setCertificateTypes] = useState([]);
    const [localCertificateTypes, setlocalCertificateTypes] = useState([]);
    const certificatedCreatedSuccesfully = 'Certificaat aangemaakt!';
    const certificateAllreadyExists = 'Dit certificaat type bestaat al!';
    useEffect(() => {         
        getDataCertificateTypes();
    },[localCertificateTypes]);

    const getDataCertificateTypes = async () => {
        const response = await getCertificateTypes();
        if(response.status === 200)
        {
            setCertificateTypes(response.certificateTypes);          
        }           
    }       

    const handleSubmit = async (e) => {
        e.preventDefault();        
        setShowCertificatedCreatedSuccesfully(false);
        setShowCertificateAllreadyExists(false);
        const response = await createCertificateType(certificateName);       
        if(response.status === 200){
            //trigger useEffect
            setlocalCertificateTypes(certificateName);
            setShowCertificatedCreatedSuccesfully(true);
        }
        if(response.status === 422)
        {
            setShowCertificateAllreadyExists(true);
        }                
    }
    
    return(
        <BlueBackground>
            <Container>
                <Wrapper>
                    <h3>
                        Bestaande certificaten
                    </h3>
                    {certificateTypes.map(item=>(
                        item.name + ', '
                    ))}

                    <h3>
                        Nieuw certificaat type registreren
                    </h3>
                    <Form onSubmit={handleSubmit}>
                        <Input value = {certificateName} onChange = {(e) => setCertificateName(e.target.value)} placeholder= 'Naam van certificaat'/>                
                        <Button>Registreer certificaat type</Button>                
                    </Form>
                    {showCertificatedCreatedSuccesfully? certificatedCreatedSuccesfully : null}
                    <ErrorMessage>
                        {showCertificateAllreadyExists? certificateAllreadyExists : null}
                    </ErrorMessage>
                </Wrapper>
            </Container>
        </BlueBackground>
    )
}
export default CreateCertificateType;

