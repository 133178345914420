import styled from 'styled-components'
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getAdmins} from "../../services/admins.service";
import Admin from './Admin';
import {BlueBackground, Container} from "../../styledComponents/globalStyles";
import {GrayButton} from "../../styledComponents/button.styles";

const List = styled.ul`
  color: white;
  list-style-type: none;
`
const Titel = styled.h1`
  color: white;
  display: inline;
`
const AdminPanelEmployees = () => { 
  const [data, setData] = useState([]) 

  useEffect(() => {
    getData();
  }, [])   
  
  const getData = async () => {
    const response = await getAdmins();
    if(response.data.status === 200)
    {
      setData(response.data.admins);      
    }     
  }  

  return (
      <BlueBackground>
        <Container>
          <Titel>Beheerders</Titel>
          <Link to='/adminpanel/nieuwebeheerder'>
            <GrayButton style={{float: "right"}}  height={"35px"} width={"30vw"}>Beheerder aanmaken</GrayButton>
          </Link>
          <List>
            {data.map(item=>(
              <Admin item = {item} key = {item.id}/>
            ))}
          </List>
        </Container>
      </BlueBackground>
  )
}
export default AdminPanelEmployees