import styled from 'styled-components';
import React, { useState } from 'react';
import AuthService from '../../services/auth.service';
import { useNavigate, Link } from 'react-router-dom';
import { Container, BlueBackground} from '../../styledComponents/globalStyles';
import { Button, ErrorMessage, ButtonWrapper} from '../../styledComponents/form.styles';

const Form = styled.form`
    padding-top: 40px;
    display: flex;
    flex-direction: column;
`
const Title = styled.h1`
    text-align: center;
    font-weight: 600;
    padding-top: 20px;
    font-size: 50px;
`
const Input = styled.input`
    flex: 1;
    min-width: 40%;
    margin: 10px 0px;
    padding: 10px;
    border-radius: 10px;
`

const StyledLink = styled(Link)`
    margin: 5px 0px; 
    text-decoration: none;
    cursor: pointer;
    color: blue;
`
const FormWrapper = styled.div`
    margin: auto;
    margin-top: 10%;
    padding: 20px;
    min-width: 250px;
    max-width: 500px;
    background-color: white;
    border-radius: 10px;
`

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');  
    const navigate = useNavigate();

    //Error messages
    const fieldRequired  = 'Dit veld is verplicht';
    const invalidCredentials = 'Je e-mail of wachtwoord is niet correct';    
    //Error states
    const [showEmptyEmail, setEmptyEmail] = useState(false);    
    const [showEmptyPassword, setEmptyPassword] = useState(false);
    const [showInvalidCredentials, setInvalidCredentials] = useState(false);
    //TODO: fix error login 401 
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(!validateInput()) return;

        const response = await AuthService.login(email, password);        
        if(response === 401){
            setInvalidCredentials(true);
            return;
        }  
        if(response === 200){     
            navigate('/Landing');
        }
              
    }
    
    const validateInput = () =>{
        let valid = true;
        //Set all the states to false, to revalidate
        setEmptyEmail(false); 
        setEmptyPassword(false);        
        setInvalidCredentials(false);

        //Validate the inputs
        if(email.length < 3){ setEmptyEmail(true); valid = false;}
        if(password.length < 3){ setEmptyPassword(true); valid = false;}
              
        return valid;
    }
    
  return (
    <BlueBackground>       
    <Container>
        <FormWrapper>
            <Title>Inloggen</Title>
            <Form onSubmit={handleSubmit}>
                <Input value = {email} onChange = {(e) => setEmail(e.target.value)} placeholder= 'Email'/>
                <ErrorMessage>{showEmptyEmail?  fieldRequired : null}</ErrorMessage>                
                <Input type="password" value = {password} onChange = {(e) => setPassword(e.target.value)} placeholder= 'Wachtwoord'/>
                <ErrorMessage>{showEmptyPassword?  fieldRequired : null}</ErrorMessage>
                <ErrorMessage>{showInvalidCredentials?  invalidCredentials : null}</ErrorMessage>        
                <ButtonWrapper>
                    <Button>Inloggen</Button>
                </ButtonWrapper>
                <StyledLink to ='/wachtwoordvergeten'>Wachtwoord vergeten</StyledLink>
                <StyledLink to = '/registreren'>Account aanmaken</StyledLink>
            </Form>
        </FormWrapper>
    </Container>
    </BlueBackground>
  )
}
export default Login;