import axiosInstance from "./axiosInstance";
  
export async function checkAvailabilityAllEployees(minTime, maxTime, selectedCompanyId) {
    const data = {minTime, maxTime, selectedCompanyId}       
    const response = await axiosInstance.post('api/checkAvailabilityAllEployees', data);
    return response;
} 

export async function postAccessToken(authCode) {
    const response = await axiosInstance.post('api/postAccessToken', {authCode: authCode});
    return response;
} 

export async function getGoogleAuthUrl() {
    const response = await axiosInstance.get('api/getGoogleAuthUrl');
    return response;
} 


