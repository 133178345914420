import styled from 'styled-components'
import {deleteEmployee} from "../../../services/companies.service";
import { useLocation , useNavigate } from 'react-router-dom';
import {
  Container,
  BlueBackground,
  RowOfElements
} from '../../../styledComponents/globalStyles';
import {GrayButton} from "../../../styledComponents/button.styles";
import {useState} from "react";
import {ErrorMessage} from "../../../styledComponents/form.styles";

const Title= styled.h1`
  color: white;
  display: inline;
`

const  EditEmployee = () => {
  const location = useLocation();
  const dataPrevPage = location.state.item;
  const company_id = location.state.company_id;
  const [canNotRemoveUser, setCanNotRemoveUser] = useState(null);

  const navigate = useNavigate();
  const deleteAdmin =  async () => {
    const response = await deleteEmployee(dataPrevPage.id, company_id);
    setCanNotRemoveUser(null);
    if(response.data.status === 200)
    {
      navigate(-1);    
    }
    if(response.data.status === 422)
    {
      setCanNotRemoveUser("Deze gebruiker heeft het bedrijf aangemaakt en kan daardoor niet worden verwijderd.")
    }
  }

return (
  <BlueBackground>
    <Container>
      <RowOfElements>
        <Title>
          Naam: {dataPrevPage.firstName + ' ' + dataPrevPage.lastName + ' '}
        </Title>
        <GrayButton style={{float: "right"}}  height={"35px"} width={"30vw"} onClick={deleteAdmin}>
            Personeelslid verwijderen
        </GrayButton>
      </RowOfElements>
      <ErrorMessage>{canNotRemoveUser? canNotRemoveUser : null}</ErrorMessage>
    </Container>
  </BlueBackground> 
  )
}
export default EditEmployee;