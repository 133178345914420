import styled from 'styled-components'
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../services/auth.service';
import {
    Container,
    BlueBackground,
    CenterDiv
} from '../../styledComponents/globalStyles';
import { Colors } from '../../styledComponents/colors';
import { ErrorMessage, Title } from '../../styledComponents/form.styles'
import validateInput from "../../hooks/validateInput";
import {BlueButton} from "../../styledComponents/button.styles";
const Wrapper = styled.div`
    padding: 20px;    
    background-color: white;
    border-radius: 10px;    
`
const Form = styled.form`
    display: flex;
    flex-direction: column;
`
const Input = styled.input`
    flex: 1;
    min-width: 60%;
    margin: 10px 0px;
    padding: 10px;
    border-radius: 10px;
    align-self: center;
`
const Agreement = styled.span`
    text-align: center;
`
const Register = (props) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [address, setAddress] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [city, setCity] = useState('');
    const navigate = useNavigate(); 


    const invalidAddress  = 'Dit is geen geldig adres volgens ons systeem';
    const [showInvalidAddress, setInvalidAddress] = useState(false);
    const [invalidInput, setInvalidInput]= useState(null);
    
    const handleSubmit = async (e) => {
        setInvalidInput(null);
        e.preventDefault();
        const validation = validateInput(firstName, lastName, password, repeatPassword, email, phoneNumber, address, zipcode, city);
        if(validation !== "valid"){
          setInvalidInput(validation);
          return;
        }

        const role = "companyAdmin";
        const response = await AuthService.register(firstName, lastName, email, password, phoneNumber,address,zipcode,city, role);
        if(response.status === 200){                        
            navigate('/Landing');
        }
        else
        {
            //Somthing is wrong
            
        }   
    }

  return (   
    <BlueBackground>  
        <Container>        
            <Wrapper>
                <Title>Registreer hier als bedrijfsbeheerder</Title>
                <Form onSubmit={handleSubmit}>
                    <Input value = {firstName} onChange = {(e) => setFirstName(e.target.value)} placeholder= 'Voornaam'/>
                    <Input value = {lastName} onChange = {(e) => setLastName(e.target.value)} placeholder= 'Achternaam'/>
                    <Input value = {email} onChange = {(e) => setEmail(e.target.value)} placeholder= 'Email'/>
                    <Input value = {phoneNumber} onChange = {(e) => setPhoneNumber(e.target.value)} placeholder= 'Telefoonnummer'/>
                    <Input value = {city} onChange = {(e) => setCity(e.target.value)} placeholder= 'Woonplaats'/>
                    <Input value = {address} onChange = {(e) => setAddress(e.target.value)} placeholder= 'Straatnaam en huisnummer'/>
                    <Input value = {zipcode} onChange = {(e) => setZipcode(e.target.value)} placeholder= 'Postcode'/>
                    <Input type="password" value = {password} onChange = {(e) => setPassword(e.target.value)} placeholder= 'Wachtwoord'/>
                    <Input type="password" value = {repeatPassword} onChange = {(e) => setRepeatPassword(e.target.value)} placeholder= 'Herhaal wachtwoord'/>
                    <Agreement>Hier een privacy policy</Agreement>
                    <ErrorMessage>{showInvalidAddress?  invalidAddress : null}</ErrorMessage>
                    <ErrorMessage>{invalidInput}</ErrorMessage>
                    <CenterDiv>
                        <BlueButton minWidth={"46vw"} minHeight={"30px"} height={"3vw"} width={"46vw"}>Registeren</BlueButton>
                    </CenterDiv>
                </Form>
            </Wrapper>
        </Container>
    </BlueBackground>
  )
}
export default Register
