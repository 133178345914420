import styled from 'styled-components';
import React, { useState } from 'react';
import AuthService from '../../services/auth.service';
import { Button, ButtonWrapper, ErrorMessage} from '../../styledComponents/form.styles'

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
`
const Wrapper = styled.div`
    padding: 20px;
    width: 30%;
    background-color: white;
    border-radius: 10px;
`
const Form = styled.form`
    display: flex;
    flex-direction: column;
`
const Title = styled.h1`
    text-align: center;
`
const Input = styled.input`
    flex: 1;
    min-width: 40%;
    margin: 10px 0px;
    padding: 10px;
    border-radius: 10px;
`

const ResetPassword = () => {
    const url = window.location.search;
    const urlParams = new URLSearchParams(url);
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPasswordIsReset, setPasswordIsReset] = useState(false); 
    const passwordIsReset = 'Uw wachtwoord is aangepast'; 
    //Error messages
    const fieldRequired  = 'Dit veld is verplicht';     
    //Error states
    const [showEmptyPassword, setEmptyPassword] = useState(false);    
    
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(!validateInput()) return;
        const token = urlParams.get('token');
        const response = await AuthService.resetPassword(token, email, password, confirmPassword);
        
       
        if(response.message === "Password reset successfully"){     
            setPasswordIsReset(true);
        }        
    }
    
    const validateInput = () =>{
        let valid = true;
        //Set all the states to false, to revalidate
        setEmptyPassword(false);       

        //Validate the inputs
        if(password.length < 3){ setEmptyPassword(true); valid = false;}     
              
        return valid;
    }

    
  return (          
    <Container>
        <Wrapper>  
            <Title>Wachtwoord vergeten</Title>
            <Form onSubmit={handleSubmit}>
                <Input value = {email} onChange = {(e) => setEmail(e.target.value)} placeholder= 'e-mail'/>
                <Input type={'password'} value = {password} onChange = {(e) => setPassword(e.target.value)} placeholder= 'wachtwoord'/>
                <Input type={'password'} value = {confirmPassword} onChange = {(e) => setConfirmPassword(e.target.value)} placeholder= 'herhaal wachtwoord'/>
                <ErrorMessage>{showEmptyPassword?  fieldRequired : null}</ErrorMessage>             
                       
                <ButtonWrapper>
                    <Button>Wachtwoord resetten</Button>
                </ButtonWrapper>                
            </Form>
            {showPasswordIsReset? passwordIsReset : null}
        </Wrapper>       
    </Container>    
  )
}

export default ResetPassword;