import { BlueBackground, Container } from "../../../styledComponents/globalStyles";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { useState, useEffect, useRef} from "react";
import { getShiftsByJobId, getRevenueByJobId, editJob } from "../../../services/jobs.service";
import { Button } from "../../../styledComponents/form.styles";
import { getCertificateTypes } from "../../../services/certificate.service";
                
const Wrapper = styled.div`
    padding: 20px;    
    background-color: white;
    border-radius: 10px;    
`
const FormWrapper = styled.div`
    padding-top: 20px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    width: 90%;
    background-color: white;
    border-radius: 10px;
`
const Form = styled.form`
    padding: 5px;
`

const BoxForSpacing = styled.div`
    padding-top: 20px;
`
const Input = styled.input`
    flex: 1;
    min-width: 40%;
    margin: 0px 10px 0px 0px;
    padding: 10px;
    border-radius: 5px;
`
const BigInput = styled.input`
    flex: 1;
    min-width: 40%;
    margin: 0px 10px 0px 0px;
    padding: 10px;
    border-radius: 5px;
`
const Salary = styled.input`
    width: 50px;    
`
const Header = styled.h4`

`
const Title = styled.h1`

`
const EditJob = () => {
    const location = useLocation();
    const job = location.state;

    const [shifts, setShifts] = useState([]);
    const [salary, setSalary] = useState('');
    const [revenue, setRevenue] = useState('');
    const [certificateTypes, setCertificateTypes] = useState([]);
    const selectedCertificates = useRef(new Array());

    const fetchShifts = async () => {        
        const response = await getShiftsByJobId(job.id);
        if(response.data.status === 200)
        {
            const res = response.data.shifts;
            setShifts(res.map(shift => {
                return {...shift, editStatus: null}
            }))

        }     
    }
    const fetchCertificateTypes = async () => {
        const response = await getCertificateTypes();
        
        if(response.status === 200)
        {              
            setCertificateTypes(response.certificateTypes);           
        }
    }

    const fetchRevenue = async () => {        
        const response = await getRevenueByJobId(job.id);
        if(response.data.status === 200)
        {
          setRevenue(response.data.revenue);         
        }     
    }

    const handleSubmit = async (e) => {
        e.preventDefault(); 
        const response = await editJob(job.id, shifts);
        if(response.data.status === 200)
        {
           // navigate('/')
        }
    }

    useEffect(() => {
        fetchCertificateTypes();       
        fetchRevenue();        
        fetchShifts();
    },[]);

    //Logic to edit the job.

    const addShift = () => {        
        setShifts((prev) => [
          ...prev,
          {
            editStatus: 'new',
            date: '',
            startTime: '',
            endTime: '',
            certificate_types: [],                  
          },
        ]);
        selectedCertificates.current[selectedCertificates.length] = certificateTypes[0];
    };

    const handleChangeShift  = (e, index) => {
        const {name, value} = e.target;
        const list = [...shifts];
        list[index][name] = value;

        let editStatus = list[index]['editStatus'];
        if(editStatus !== 'new'){
            list[index]['editStatus'] = 'edited';
        }
        setShifts(list);   
    }

    const isRan = useRef(false);

    const addCertificate = (index) => {
        //on first time the function is executed
        if(!isRan.current)
        {
            createSelectedCertificateArray();
            isRan.current = true;
        }
        
        const list = [...shifts];     
        list[index].certificate_types.push(selectedCertificates.current[index]);

        let editStatus = list[index]['editStatus'];
        if(editStatus !== 'new'){
            list[index]['editStatus'] = 'edited';  
        }

        setShifts(list);          
    }

    
    const createSelectedCertificateArray = () => {
        //Create enough selectedCertificates          
        for(let i = 0; i < shifts.length; i++)
        {
            selectedCertificates.current[i] = certificateTypes[0];                 
        }
    }
    
    const handleChangeSelectedCertificate = (e, index) => {
        let obj = JSON.parse(e.target.value);
        selectedCertificates.current[index] = obj;
    }

    const deleteShift = (index) => {
        const list = [...shifts];
        list[index]['editStatus'] = 'deleted';       
        setShifts(list);
    }

    return (        
        <BlueBackground>
            <Container>
                <FormWrapper>       
                    <Wrapper>
                        <Form onSubmit={handleSubmit}>
                            <Title>Opdracht bewerken</Title>                        
                            <BoxForSpacing/>        
                            <Header>
                                Titel van de opdracht
                            </Header>
                            <Input value = {job.title}/>
                            
                            <BoxForSpacing/> 
                            <Header>De locatie:</Header>
                                <Input value = {job.city}/>                         
                                <Input value = {job.address}/>
                                <Input value = {job.zipcode}/>                            
                            
                            <BoxForSpacing/>
                            <Header>
                                Voeg hieronder de verschillende diensten toe dat uw klus heeft met per dienst de tijden en de benodigde certificaten.
                            </Header>
                            {shifts.map((singleShift, index) => (
                                <div>
                                <BoxForSpacing/> 
                                <div style={{outline: "2px dashed black"}}>                
                                    <Header>Datum en tijd:</Header>
                                    <div>
                                    Selecteer een datum:                           
                                    <Input name="date" value = {singleShift.date} onChange = {(e) => handleChangeShift(e, index)} type='date'/>
                                    </div>
                                    <div>
                                        Selecteer een begintijd:
                                        <Input name="startTime" value = {singleShift.startTime} onChange = {(e) => handleChangeShift(e, index)} type ='time'/>
                                    </div>
                                    <div>
                                        Selecteer een eindtijd:
                                        <Input name="endTime" value = {singleShift.endTime} onChange = {(e) => handleChangeShift(e, index)} type ='time'/>                
                                    </div>                                    
                                    <BoxForSpacing/>
                                    <Header>Diploma's en certificaten:</Header>
                                                        
                                    <select name='selectedCertificate' onChange={(e) => handleChangeSelectedCertificate(e, index)}>
                                        {certificateTypes.map(certificateType => (
                                        <option value={JSON.stringify(certificateType)}>
                                            {certificateType.name}
                                        </option>
                                        ))}
                                    </select>                             
                                    <button type ='button' onClick={()=> addCertificate(index)}>Certificaat toevoegen</button>                                                                                   
                                        <div>
                                            <table>
                                                <tbody>                                                    
                                                    <tr><th>Benodigde certificaten:</th></tr>                                                
                                                    {                                                     
                                                        singleShift.certificate_types.map(certificate => (
                                                            <tr><td> {certificate.name}</td></tr> 
                                                        ))
                                                    }
                                                </tbody>
                                            </table>    
                                        </div>                                           
                                    <BoxForSpacing/>
                                </div>
                                <button type = 'button' onClick={() => deleteShift(index)}>Dienst verwijderen</button>
                            </div>
                            ))}
                            <button type ='button' onClick={addShift}>Nog een dienst toevoegen</button>
                            <Header>
                                Vergoeding die JBG ontvangt:  
                            </Header>
                            <div>                               
                                € <Salary value = {revenue} size="20"/> Per uur 
                            </div>                       
                            <BoxForSpacing/>
                            <Header>
                                De opdracht omschrijving:
                            </Header>
                            <BigInput value = {job.description}/> 
                            <BoxForSpacing/>
                            <Header>
                                Vergoeding die de brandwacht ontvangt:  
                            </Header>
                            <div>                               
                                € <Salary value = {job.salary} onChange = {(e) => setSalary(e.target.value)} placeholder='--' size="20"/> Per uur 
                            </div>                  
                            <BoxForSpacing/>
                            <Button>Opdracht aanpassen</Button>                                               
                        </Form>           
                    </Wrapper>
                </FormWrapper>    
            </Container>
        </BlueBackground>
    );
}
export default EditJob;