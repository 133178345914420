import styled from 'styled-components'
import { Button } from '../../../styledComponents/form.styles'
const Container = styled.div`
    margin-top: 20px;
    padding: 20px;
    height: 600px;
    width: 300px;
    background-color: white;
    border-radius: 10px;
`

export default function Filter() {
  return (
    <div>    
      <Container>
        <h1>Filter</h1>
        <label for="distance">Kies een afstand: </label>
        <select name="distance" id="distance">
          <option value="volvo">Alle afstanden</option>
          <option value="volvo">{'< 25'}</option>
          <option value="saab">{'< 50'}</option>
          <option value="mercedes">{'< 75'}</option>
          <option value="audi">{'< 100'}</option>
        </select>
        <Button>Zoek</Button>
      </Container>
    </div>
  )
}
