import styled from 'styled-components'
import {Link, useLocation, useNavigate} from 'react-router-dom';
import { useState, useEffect } from 'react';
import {getEmployees} from "../../../services/companies.service";

import {
  Container,
  BlueBackground,
  RowOfElements
} from '../../../styledComponents/globalStyles';
import {ResponsiveTableDiv} from "../../../styledComponents/form.styles";
import {GrayButton} from "../../../styledComponents/button.styles";

const List = styled.ul`
    color: white; 
    list-style-type: none;   
`
const Titel = styled.h1`
  color: white;
  display: inline;
`
const ListItem = styled.li`
  border-style: groove;
  margin: 7px 0;
  line-height: 35px;
`

const Employees = () => {
  const location = useLocation();
  const [data, setData] = useState([])
  const company_id = location.state.selectedCompanyId.companyId;

  useEffect(() => {    
    getData();
  }, [])

  const navigate = useNavigate();
  const handleClick =  (item) => {
    navigate('/bedrijfsbeheer/bewerkpersoneel', { state: {item, company_id}});
  }

  const getData = async () => {
    //this data passing is junk, but redux needed an init on a page refresh
    const response = await getEmployees(location.state.selectedCompanyId.companyId);
    if(response.data.status === 200)
    {
      setData(response.data.employees);      
    }     
  }      
  return (
    <BlueBackground>
      <Container>
      <RowOfElements>
        <Titel>Personeel</Titel>      
        <Link to='/bedrijfsbeheer/nieuwpersoneel'>
          <GrayButton style={{float: "right"}}  height={"35px"} width={"30vw"}>Personeel aanmaken</GrayButton>
        </Link>
      </RowOfElements>
        <ResponsiveTableDiv>
          <List>
            {data.map(item=>(
                <ListItem>
                  Naam: {item.firstName + ' ' + item.lastName + ' '}
                  <GrayButton style={{float: "right", textAlign: "center"}}  height={"20px"} width={"100px"} onClick={()=>handleClick(item)}>
                    Bewerken
                  </GrayButton>
                </ListItem>
            ))}
          </List>
        </ResponsiveTableDiv>
      </Container>
    </BlueBackground>
  )
}
export default Employees