const initState = {
    isGoogleAgendaLinked: false,      
  }
  
  const googleAgendaReducer = (state = initState, action) => {
      switch(action.type){
          case 'ISLINKED':
              return {...state, isGoogleAgendaLinked:action.payload}          
          default: return state;
      }
  }
  export default googleAgendaReducer
  
  
  