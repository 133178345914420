import styled from 'styled-components'
import React, { useState } from 'react';
import {createCompany} from "../../../services/companies.service";
import { useNavigate } from 'react-router-dom';
import { BlueBackground } from '../../../styledComponents/globalStyles';
import { ButtonWrapper, Button, ErrorMessage } from '../../../styledComponents/form.styles';

const Wrapper = styled.div`
    padding: 20px;
    width: 40%;
    background-color: white;
    border-radius: 10px;
`
const Form = styled.form`
    display: flex;
    flex-direction: column;
`
const Title = styled.h1`
    text-align: center;
`
const Input = styled.input`
    flex: 1;
    min-width: 40%;
    margin: 10px 0px;
    padding: 10px;
    border-radius: 10px;
`
const Agreement = styled.span`
`

const Container = styled.div`
    display: flex;
    justify-content: center;
`



const CreateCompany = () => {
    const navigate = useNavigate();
    const [companyName, setFirstName] = useState('');
    const [kvkNumber, setKvkNumber] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [address, setAddress] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [city, setCity] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(!validateInput()) return;

        const response = await createCompany(companyName, kvkNumber, phoneNumber, address, zipcode, city);

        if(response.data.status === 200)
        {
            navigate('/bedrijven');
            console.log(response.data.message);            
        }
    }

    //Simple input handling
    const requiredFieldError = 'Alle velden zijn verplicht, controleer wat u heeft ingevuld.'
    const [showRequiredFieldError, setShowRequiredFieldError] = useState(false);
    const validateInput = () =>{
        let valid = true;
        //Set all the states to false, to revalidate
        setShowRequiredFieldError(false); 
        
        //Validate the inputs
        if(companyName.length < 1){ setShowRequiredFieldError(true); valid = false;}
        if(kvkNumber.length < 1){ setShowRequiredFieldError(true); valid = false;}
        if(phoneNumber.length < 10 ){ setShowRequiredFieldError(true); valid = false;}
        if(address.length < 3){ setShowRequiredFieldError(true); valid = false;}
        if(zipcode.length < 3){ setShowRequiredFieldError(true); valid = false;}
        if(city.length < 3){ setShowRequiredFieldError(true); valid = false;}
        return valid;
    }

  return (
    <BlueBackground>
        <Container>        
            <Wrapper>
                <Title>Maak een bedrijf aan</Title>
                <Form onSubmit={handleSubmit}>
                    <Input value = {companyName} onChange = {(e) => setFirstName(e.target.value)} placeholder= 'Naam van het bedrijf'/>
                    <Input value = {kvkNumber} onChange = {(e) => setKvkNumber(e.target.value)} placeholder= 'Het KVK-nummer van uw bedrijf'/>
                    <Input value = {phoneNumber} onChange = {(e) => setPhoneNumber(e.target.value)} placeholder= 'Het telefoonnummer van uw bedrijf'/>
                    <Input value = {address} onChange = {(e) => setAddress(e.target.value)} placeholder= 'Het adres van uw bedrijf'/>
                    <Input value = {zipcode} onChange = {(e) => setZipcode(e.target.value)} placeholder= 'De postcode'/>
                    <Input value = {city} onChange = {(e) => setCity(e.target.value)} placeholder= 'De plaats'/>
                    <ErrorMessage>{showRequiredFieldError?  requiredFieldError : null}</ErrorMessage>
                    <Agreement>Hier een privacy policy</Agreement>
                    <ButtonWrapper>
                        <Button>Bedrijf aanmaken</Button>
                    </ButtonWrapper>
                </Form>
            </Wrapper>
        </Container>
    </BlueBackground>    
  )
}

export default CreateCompany
