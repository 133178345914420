import { BlueBackground, Container } from '../../styledComponents/globalStyles'
import CompanyAdminHomeHints from './landingHints/CompanyAdminLandingHints'
import BrandwachtHomeHints from './landingHints/BrandwachtLangingHints'
import authService from '../../services/auth.service';
import { useSelector } from 'react-redux';

export default function Landing() {
  const roles = useSelector(state => state.roles);
  const isCompanyAdmin = authService.checkIfUserHasRole('bedrijfsbeheerder', roles);
  const isBrandwacht = authService.checkIfUserHasRole('brandwacht', roles);

  return (
    <BlueBackground>
      <Container>
        {isCompanyAdmin? <CompanyAdminHomeHints/> : null}
        {isBrandwacht? <BrandwachtHomeHints/> : null}
      </Container>
    </BlueBackground>
  )
}
