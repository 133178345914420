import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../../services/auth.service';
import { Form, Input, ErrorMessage, FormWrapper} from '../../../styledComponents/form.styles'
import { Container } from '../../../styledComponents/globalStyles'
import { Colors } from '../../../styledComponents/colors';
import styled from 'styled-components';
import {BlueButton} from "../../../styledComponents/button.styles";
import validateInput from "../../../hooks/validateInput";

const Title = styled.h1`
  font-size: 50px;
  color: ${Colors.JbgBlue};
  
`
const Box = styled.div`
    height: 50px;
`

const Register = () => {
    const [address, setAddress] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [city, setCity] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const navigate = useNavigate();

    const [showInvalidAddress, setInvalidAddress] = useState(false);
    const invalidAddress  = 'Dit is geen geldig adres volgens ons systeem';
    const [invalidInput, setInvalidInput]= useState(null);

    const handleSubmit = async (e) => {
        setInvalidInput(null);
        e.preventDefault();
        const validation = validateInput(firstName, lastName, password, repeatPassword, email, phoneNumber, address, zipcode, city);
        if(validation !== "valid"){
            setInvalidInput(validation);
            return;
        }
        const role = "brandwacht";
        const data = await AuthService.register(firstName, lastName, email, password, phoneNumber,address,zipcode,city, role);

        if(data.status === 200){                        
            navigate('/Landing');
        }
        else
        {
            //Something is wrong
            if(data === 'Address not found')
            {
                setInvalidAddress(true);
            }
        }
    }
  return (    
    <Container>        
        <Box/>
        <Title>
            Maak hier een account aan
        </Title>
        <FormWrapper>
            <Form onSubmit={handleSubmit}>
                <Input minWidth={"50vw"} value = {firstName} onChange = {(e) => setFirstName(e.target.value)} placeholder= 'Voornaam'/>
                <Input minWidth={"50vw"} value = {lastName} onChange = {(e) => setLastName(e.target.value)} placeholder= 'Achternaam'/>
                <Input minWidth={"50vw"} value = {email} onChange = {(e) => setEmail(e.target.value)} placeholder= 'Email'/>
                <Input minWidth={"50vw"} value = {phoneNumber} onChange = {(e) => setPhoneNumber(e.target.value)} placeholder= 'Telefoonnummer'/>
                <Input minWidth={"50vw"} value = {city} onChange = {(e) => setCity(e.target.value)} placeholder= 'Woonplaats'/>
                <Input minWidth={"50vw"} value = {address} onChange = {(e) => setAddress(e.target.value)} placeholder= 'Straatnaam en huisnummer'/>
                <Input minWidth={"50vw"} value = {zipcode} onChange = {(e) => setZipcode(e.target.value)} placeholder= 'Postcode'/>
                <Input minWidth={"50vw"} type="password" value = {password} onChange = {(e) => setPassword(e.target.value)} placeholder= 'Wachtwoord'/>
                <Input minWidth={"50vw"} type="password" value = {repeatPassword} onChange = {(e) => setRepeatPassword(e.target.value)} placeholder= 'Herhaal wachtwoord'/>
                <ErrorMessage>{showInvalidAddress?  invalidAddress : null}</ErrorMessage>
                <ErrorMessage>{invalidInput}</ErrorMessage>
                <BlueButton minWidth={"50vw"} height={"3vw"} minHeight={"30px"} width={"50vw"}>Registeren</BlueButton>
            </Form>
        </FormWrapper>
        <Box/>
    </Container>
  )
}
export default Register;
