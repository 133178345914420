const validateInput = (firstName, lastName, password, repeatPassword, email, phoneNumber, address, zipcode, city, isZzp, companyName, kvkNumber) => {
  //Error messages
  const passwordsDoNotMatch  = 'De twee opgegeven wachtwoorden komen niet overeen';
  const invalidName  = 'De voor en achternaam velden zijn verplicht';
  const invalidEmail  = 'Dit is geen geldig e-mailadres';
  const invalidPhonenumber  = 'Dit is geen geldig telefoonnummer';
  const invalidAddress  = 'Dit is geen geldig adres volgens ons systeem';
  const invalidZipcode  = 'Dit is geen geldige postcode';
  const invalidCity  = 'Dit is geen geldige woonplaats';
  const kvkOrCompanyName = 'KVK-nummer of bedrijfsnaam is niet ingevuld';

  //Validate the inputs
  if(firstName.length < 3 || lastName.length < 3) return invalidName;
  if(password !== repeatPassword) return passwordsDoNotMatch;
  if(!email.includes('@')) return invalidEmail;
  if(phoneNumber.length !== 10) return invalidPhonenumber;
  if(address.length < 3) return invalidAddress;
  if(zipcode.length < 3) return invalidZipcode;
  if(city.length < 3) return invalidCity;
  if(isZzp)
  {
    if(companyName.length < 2 || kvkNumber.length < 2)return kvkOrCompanyName;
  }
  return "valid";
}
export default validateInput;