import styled from 'styled-components'
import React, { useState, useEffect, useRef} from 'react';
import { Container, BlueBackground} from '../../styledComponents/globalStyles';
import { ErrorMessage } from '../../styledComponents/form.styles';
import { Colors } from '../../styledComponents/colors';
import { getCertificateTypes, registerCertificateByUser_id, getCertificatesSpecificUser } from '../../services/certificate.service';
import { registerBrandwachtByEmployee } from '../../services/binnendienst.service';

const Wrapper = styled.div`
    padding: 20px;    
    background-color: white;
    border-radius: 10px;    
`
const Form = styled.form`
    display: flex;
    flex-direction: column;
`
const Title = styled.h1`
    text-align: center;
`
const Input = styled.input`
    flex: 1;
    min-width: 60%;
    max-width: 80%;
    margin: 10px 0px;
    padding: 10px;
    border-radius: 10px;
    align-self: center;
`
const Button = styled.button`
    height: 40px;
    border: none;
    padding: 15px, 20px;
    background-color : ${Colors.JbgBlue};
    color: white;
    font-weight: bold;
    font-size: 15px;
    cursor: pointer;
    border-radius: 25px;
    justify-content: center;
    margin-left : 10px;
    min-width: 60%;
    align-self: center;
`

const CenteredDiv = styled.div`
    align-self: center;
   
`
const Table = styled.table`
  border-spacing: 50px 0;
  border: 1px solid;
  color: black;
`

const RegisterUsers = () =>{
    //user info
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [city, setCity] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isZzp, setIsZzp] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [kvkNumber, setKvkNumber] = useState('');

    //certificate info
    const [expiryDate, setExpiryDate] = useState('');
    const [certificateTypes, setCertificateTypes] = useState([]);
    const [usersCertificates, setUsersCertificates] = useState([]);
    //the following is just used to trigger useEffect
    const [localRegisteredCertificates, setlocalRegisteredCertificates] = useState([]);
    const [selectedCertificateTypeId, setSelectedCertificateTypeId] = useState('');
    
    
    //The password from server
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [user_id, setUser_id] = useState('');
    const [userName, setUserName] = useState('');
    const [isUserCreated, setIsUserCreated]= useState(false);

    //Error messages   
    const invalidName  = 'De voor en achternaam velden zijn verplicht';
    const invalidEmail  = 'Dit is geen geldig e-mailadres';
    const invalidPhonenumber  = 'Dit is geen geldig telefoonnummer';
    const invalidAddress  = 'Dit is geen geldig adres volgens ons systeem';
    const invalidZipcode  = 'Dit is geen geldige postcode';
    const invalidCity  = 'Dit is geen geldige woonplaats';
    const required = 'Dit veld is verplicht';    //Error states
    const [showInvalidName, setInvalidName] = useState(false);    
    
    const [showInvalidEmail, setInvalidEmail] = useState(false);
    const [showInvalidPhonenumber, setInvalidPhonenumber] = useState(false);
    const [showInvalidAddress, setInvalidAddress] = useState(false);
    const [showInvalidZipcode, setInvalidZipcode] = useState(false);
    const [showInvalidCity, setInvalidCity] = useState(false);
    const [showInvalidKvk, setInvalidKvk] = useState(false);
    const [showInvalidCompanyName, setInvalidCompanyName] = useState(false);

    const isFirstRender = useRef(true);
    useEffect(() => {
        if(isFirstRender.current)
        {
            getDataCertificateTypes();            
            isFirstRender.current = false; 
            return;          
        } 
        if(isUserCreated) getCertificatesByUser_id();
        setSelectedCertificateTypeId(certificateTypes[0].id)              
      },[localRegisteredCertificates, certificateTypes]);


    const getDataCertificateTypes = async () => {
        const response = await getCertificateTypes();
        if(response.status === 200)
        {
          setCertificateTypes(response.certificateTypes);
          
        }     
    }

    const getCertificatesByUser_id = async () => {
        const response = await getCertificatesSpecificUser(user_id);
        if(response.status === 200)
        {
            setUsersCertificates(response.certificates);
          
        }     
    }   
   
    const validateInput = () =>{
        let valid = true;
        //Set all the states to false, to revalidate
        setInvalidName(false); 
        setInvalidName(false);         
        setInvalidEmail(false); 
        setInvalidPhonenumber(false); 
        setInvalidAddress(false); 
        setInvalidZipcode(false); 
        setInvalidCity(false); 
        setInvalidKvk(false);
        setInvalidCompanyName(false);
        //Validate the inputs
        if(firstName.length < 3){ setInvalidName(true); valid = false;}
        if(lastName.length < 3){ setInvalidName(true); valid = false;}
        if(!email.includes('@')){ setInvalidEmail(true); valid = false;}
        if(phoneNumber.length !== 10){ setInvalidPhonenumber(true); valid = false;}
        if(address.length < 3){ setInvalidAddress(true); valid = false;}
        if(zipcode.length < 3){ setInvalidZipcode(true); valid = false;}
        if(city.length < 3){ setInvalidCity(true); valid = false;}
        if(isZzp)
        {
            //check zpp fields
            if(companyName.length < 2){setInvalidCompanyName(true); valid = false}
            if(kvkNumber.length < 2){setKvkNumber(true); valid = false}
        }
        return valid;
    }


    const handleSubmit = async (e) => {
        e.preventDefault();        
        if(!validateInput()) return;
        const response = await registerBrandwachtByEmployee(firstName, lastName, email, phoneNumber, address, zipcode, city, isZzp, companyName, kvkNumber);
        if(response.data.status === 200){
            setUser_id(response.data.user.id);
            setUserName(response.data.user.firstName + ' ' +response.data.user.lastName);
            setPassword(response.data.password);                 
            setShowPassword(true);
            setIsUserCreated(true);
        }   
    }

    
    const registerCertificateHandler = async () => {         
   
        const data = await registerCertificateByUser_id(user_id, selectedCertificateTypeId, expiryDate);
    
        if(data.status === 200){
          //trigger useEffect                      
          setlocalRegisteredCertificates(selectedCertificateTypeId);
        }
        else
        {
          //Something is wrong
            
        }
      }
        
    const handleZzpCheckBox = (e) => {
        setIsZzp(current => !current);
    }  

    const handleChange = (event) => {
        setSelectedCertificateTypeId(event.target.value);            
    }
    return (  
    <BlueBackground> 
        <Container>        
            <Wrapper>                
                { isUserCreated?                
                    <div>
                    <CenteredDiv>
                        <Title>
                            Certificaten
                        </Title>
                        Brandwacht: { userName } is aangemaakt!
                            Voeg nu zijn certificaten toe.
                        
                            <div>
                                Naam van certificaat:
                                <select onChange={handleChange}>
                                    {certificateTypes.map(certificateType => (
                                        <option value={certificateType.id}>
                                            {certificateType.name}
                                        </option>
                                    ))
                                    }
                                </select>
                                &nbsp; &nbsp;
                                Verloopdatum:                        
                                <input value = {expiryDate} onChange = {(e) => setExpiryDate(e.target.value)} type ='date'/>
                                &nbsp; &nbsp;
                                <button onClick={registerCertificateHandler}>Certificaat toevoegen</button>
                            </div>
                            <Table>
                                <tr>
                                    <th>Certificaat</th>
                                    <th>Verloop datum</th>                            
                                </tr>
                                {usersCertificates.map(item=>(                            
                                    <tr>
                                        <td>
                                        {item.name}
                                        </td>
                                        <td>
                                        {item.expiryDate}
                                        </td>
                                    </tr>
                                    ))}
                            </Table>
                    </CenteredDiv>  
                </div> 
                    :
                    <div>
                        <Title>Nodig een brandwacht uit</Title>
                        <Form>
                            <Input value = {firstName} onChange = {(e) => setFirstName(e.target.value)} placeholder= 'Voornaam'/>
                            <Input value = {lastName} onChange = {(e) => setLastName(e.target.value)} placeholder= 'Achternaam'/>                
                            <ErrorMessage>{showInvalidName?  invalidName : null}</ErrorMessage>  
                            <Input value = {email} onChange = {(e) => setEmail(e.target.value)} placeholder= 'Email'/>
                            <ErrorMessage>{showInvalidEmail?  invalidEmail : null}</ErrorMessage>
                            <Input value = {phoneNumber} onChange = {(e) => setPhoneNumber(e.target.value)} placeholder= 'Telefoonnummer'/>
                            <ErrorMessage>{showInvalidPhonenumber?  invalidPhonenumber : null}</ErrorMessage>
                            <Input value = {city} onChange = {(e) => setCity(e.target.value)} placeholder= 'Woonplaats'/>
                            <ErrorMessage>{showInvalidCity?  invalidCity : null}</ErrorMessage>                
                            <Input value = {address} onChange = {(e) => setAddress(e.target.value)} placeholder= 'Straatnaam en huisnummer'/>
                            <ErrorMessage>{showInvalidAddress?  invalidAddress : null}</ErrorMessage>
                            <Input value = {zipcode} onChange = {(e) => setZipcode(e.target.value)} placeholder= 'Postcode'/>
                            <ErrorMessage>{showInvalidZipcode?  invalidZipcode : null}</ErrorMessage>
                            <div style={{paddingLeft: '20%'}}>
                                Is de brandwacht een zzp'er? 
                                <input type="checkbox" onChange={e => handleZzpCheckBox(e)}/>                
                            
                                {isZzp? 
                                    <div>
                                        <Input value = {companyName} onChange = {(e) => setCompanyName(e.target.value)} placeholder= 'Bedrijfsnaam'/>
                                        <ErrorMessage>{showInvalidCompanyName?  required : null}</ErrorMessage> 
                                        <Input value = {kvkNumber} onChange = {(e) => setKvkNumber(e.target.value)} placeholder= 'KVK-nummer'/>
                                        <ErrorMessage>{showInvalidKvk?  required : null}</ErrorMessage> 
                                    </div>
                                    :
                                    null
                                }
                            </div>                                                   
                            <Button onClick={handleSubmit}>Brandwacht uitnodigen</Button>                    
                        </Form>
                        {
                            showPassword? 'De nieuwe gebruiker zijn wachtwoord: ' + password : null
                        }
                    </div>
                }                            
            </Wrapper>
        </Container>
    </BlueBackground>    
  )  
}

export default RegisterUsers;

