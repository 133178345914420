import styled from 'styled-components'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import EuroSymbolOutlinedIcon from '@mui/icons-material/EuroSymbolOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import {claimJob} from "../../services/jobs.service";
import { BlueBackground } from '../../styledComponents/globalStyles';
import { Button, ErrorMessage } from '../../styledComponents/form.styles';
import { useState } from 'react';

const Container = styled.div`
    width: 100%;    
    display: flex;    
    justify-content: center;
    padding-top: 20px;
`
const Wrapper = styled.div`
    padding: 20px;
    width: 80%;
    background-color: white;
    border-radius: 10px;
`

const LocationDate = styled.div`
  display: flex;
`

const JobDetails = () => {
    const location = useLocation();
    const data = location.state;
    const navigate = useNavigate();
    const [shiftisClaimed, SetShiftIsClaimed] = useState(false);
    const [notAllRequiredCertificates, SetNotAllRequiredCertificates] = useState(false);
    const errorMessageRequiredCertificates = 'U heeft niet alle benodigde certificaten.';
    const errorMessageShiftClaimed = 'Een van de diensten is al bezet.'

    const handleClick = async (e) => {       
        const response = await claimJob(data.job.id);        
        
        if(response.data.status === 200)
        {
            navigate('/mijn-opdrachten');
        }
        else if(response.data.status === 401)
        {
            if(response.data.message === 'One of the shifts is allready claimed')
            {
                SetShiftIsClaimed(true);
            }
            else if(response.data.message === 'You do not have the right certificates')
            {
                SetNotAllRequiredCertificates(true);
            }
        }
    }

  return ( 
    <BlueBackground>          
        <Container>
            <Wrapper>
            <div>
                <h2>{data.job.title}</h2>
            </div>
            <LocationDate>
                <div><LocationOnOutlinedIcon />{data.job.city}</div>               
            </LocationDate>

            {data.job.shifts.map((shift) => (
              <div>
                    <LocationDate>
                        <div><CalendarMonthOutlinedIcon/>{shift.date}</div> 
                        <div><AccessTimeOutlinedIcon/>{shift.startTime}-{shift.endTime}</div>
                    </LocationDate>
                    <div>
                    Certificaten: 
                    {shift.certificate_types.map((certificate) =>(
                    certificate.name + ' '
                    ))}
                </div>
              </div>
            ))
            }
            <div>
                <EuroSymbolOutlinedIcon/>{data.job.salary} per uur
            </div>           
            <div>    
                {data.job.description}
            </div>    
            <Button onClick={handleClick}>Opdracht aannemen</Button>
            <ErrorMessage>
                {shiftisClaimed? errorMessageShiftClaimed : null}
                {notAllRequiredCertificates? errorMessageRequiredCertificates : null}
            </ErrorMessage>            
            </Wrapper>
        </Container>       
    </BlueBackground>  
  )
}
export default JobDetails;