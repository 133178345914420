import axios from "axios";
import store from "../../src/redux/reducers/rootReducer"
import { login } from "../../src/redux/actions/login.action";
import { roles } from "../redux/actions/setRoles.action";
import { setGoogleAgendaLinked}  from "../redux/actions/setGoogleAgendaLinked.action";
import { verified } from "../redux/actions/verified.action";
import axiosInstance from "./axiosInstance";

class AuthService {   
     
    login(email, password)
    {
        return axios.post('api/user/login', {email, password}).then((response) =>{
            if(response.status === 200 && response.data.token)
            {
                localStorage.setItem("user", JSON.stringify(response.data));
                store.dispatch(login(true));
                store.dispatch(roles(response.data.roles)); 
                store.dispatch(verified(response.data.isVerified));
                store.dispatch(setGoogleAgendaLinked(response.data.googleAgendaLinked));                         
            }            
            return response.status;
        }).catch(function (error){
            if(error.response.status === 401){
                return 401;
              }
        });       
    }

    logout() 
    {                   
        const getData = async () => {
                await axiosInstance.post('api/user/logout');                   
            }  
        getData();
        store.dispatch(login(false));
        localStorage.removeItem("user");         
        store.dispatch(verified(0));
              
    }

    register(firstName, lastName, email, password, phoneNumber, address, zipcode, city, role)
    {
        return axios.post('api/user/register', {firstName, lastName, email, password, phoneNumber, address, zipcode, city, role}).then((response) =>{
            if(response.data.status === 200)
            {
                localStorage.setItem("user", JSON.stringify(response.data));
                store.dispatch(login(true));
                store.dispatch(roles(response.data.roles));
                store.dispatch(verified(response.data.isVerified));         
            }
            return response.data;            
        });       
    }

    registerZzper(firstName, lastName, email, password, phoneNumber, address, zipcode, city, companyName, kvkNumber)
    {
        return axios.post('api/register-zzper', {firstName, lastName, email, password, phoneNumber, address,zipcode,city, companyName, kvkNumber}).then((response) =>{
            if(response.data.status === 200)
            {
                localStorage.setItem("user", JSON.stringify(response.data));
                store.dispatch(login(true));
                store.dispatch(roles(response.data.roles));
                store.dispatch(verified(response.data.isVerified));         
            }
            return response.data;            
        });       
    }

    registerEmployee(firstName, lastName, email, phoneNumber, companyId, city, address, zipcode)
    {
        return axiosInstance.post('api/registerEmployee', {firstName, lastName, email, phoneNumber, companyId, city, address, zipcode}).then((response) =>{
            return response;           
        });       
    }

    registerAdmin(firstName, lastName, email, phoneNumber, city, address, zipcode)
    {
        return axiosInstance.post('api/createAdmin', {firstName, lastName, email, phoneNumber, city, address, zipcode}).then((response) =>{
            return response;           
        });       
    }
    
    getUserData()
    {
        return axiosInstance.get('api/me').then((response) =>{
          if(response.status === 200)
          {             
            store.dispatch(login(true));
            store.dispatch(roles(response.data.roles));     
            store.dispatch(setGoogleAgendaLinked(response.data.googleAgendaLinked)); 
            store.dispatch(verified(response.data.isVerified));    
          }
          else
          {
            store.dispatch(login(false));
          }  
          return response;      
      });
    }

    changeAccountDetails(email, phoneNumber,address,zipcode,city)
    {
        return axiosInstance.post('api/changeAccountDetails', {email, phoneNumber, city, zipcode, address}).then((response) =>{
            return response.data;            
        });       
    }

    changePassword(currentPassword, newPassword)
    {
        return axiosInstance.post('api/changePassword', {currentPassword, newPassword}).then((response) =>{
            return response.data;            
        });       
    }

    forgotPassword(email)
    {
        return axios.post('api/forgotPassword', {email}).then((response) =>{
            return response.data;            
        });       
    }

    resetPassword(token, email, password, password_confirmation)
    {
        return axios.post('api/resetPassword', {token, email, password, password_confirmation}).then((response) =>{
            return response.data;            
        });       
    }

    checkIfUserHasRole(role, rolesArray)
    {           
        for (let i = 0; i < rolesArray.roles.length; i++) {
            if(role === rolesArray.roles[i]){ return true;};
        }        
        return false;
    }
}
export default new AuthService();