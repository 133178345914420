const initState = {
    companyId: null,      
  }
  
  const selectCompanyReducer = (state = initState, action) => {
      switch(action.type){
          case 'COMPANYID':
              return {...state, companyId:action.payload}
          default: return state;
      }
  }
  export default selectCompanyReducer