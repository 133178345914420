import { BlueBackground, Container, MenuBox, MenuWrapper } from "../../styledComponents/globalStyles";
import { useNavigate } from "react-router-dom";

const ManageCertificate = () => {
                 
    let navigate = useNavigate(); 
    const route1 = () =>{ 
        let path = `certificatenmaandtotverloop`; 
        navigate(path);
    }

    const route2 = () =>{ 
        let path = `certificaatTypeAanmaken`; 
        navigate(path);
    }  
      
    return(
        <BlueBackground>
            <Container>
                <MenuWrapper>
                    <MenuBox onClick={route1}>
                        Certificaten die binnen een maand verlopen
                    </MenuBox>
                    <MenuBox onClick={route2}>
                        Certificaat-type aanmaken
                    </MenuBox>
                </MenuWrapper>
            </Container>
        </BlueBackground>
    )
}
export default ManageCertificate;

