import authService from '../../services/auth.service';
import {MenuItem, MenuItemWrapper} from "../../styledComponents/navbar.styles"

export default function BrandwachtLinks() {

  const handleClick = async (e) => {     
    authService.logout();       
};
  return (    
      <MenuItemWrapper>
        <li>
          <span>
            <MenuItem to='/opdrachten'>Openstaande opdrachten </MenuItem>
          </span>
        </li>
        <li>
          <span>
          <MenuItem  to='/mijn-opdrachten'>Mijn opdrachten </MenuItem>
          </span>
        </li>
        <li>
          <span>
          <MenuItem  to='/certificaten'>Mijn certificaten </MenuItem>
          </span>
        </li>        
        <li>
          <span>
          <MenuItem onClick={handleClick} to='/'>Uitloggen </MenuItem>
          </span>
        </li>
        <li>
          <span>
          <MenuItem  to='/profile'> Account </MenuItem>
          </span>
        </li>             
      </MenuItemWrapper>  
  )
}
