import styled from 'styled-components'
import { BlueBackground, Container} from '../../styledComponents/globalStyles';

const Titel = styled.h1`
  color: white;
`

const NoCompaniesFound = () => {    
    return (   
      <BlueBackground>  
        <Container>
            <Titel>U heeft nog geen bedrijf in ons systeem, maak een bedrijf aan</Titel> 
        </Container>        
      </BlueBackground>   
    )
}
export default NoCompaniesFound