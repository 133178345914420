import styled from 'styled-components';
import { BlueBackground, Container } from '../../../styledComponents/globalStyles'
import Certificates from '../../../components/common/Certificates'
const Title = styled.h1`
    color: white; 
`
const Text = styled.p`
  color: white;
  padding-bottom: 40px;
`
export default function WaitForVerification() {
    return (
      <BlueBackground>
        <Container>
          <Title>
            Welkom!
          </Title>
          <Text>
            Uw gegevens worden door ons geverifieerd. Ons team zal contact met u opnemen. Tot die tijd kan u alvast u behaalde certificaten opgeven.
          </Text>
          <Certificates/>
        </Container>
      </BlueBackground>
    )
  }
  