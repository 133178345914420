import {MenuItem, MenuItemWrapper} from "../../styledComponents/navbar.styles"


export default function SignedOutLinks() {
  return ( 
    <MenuItemWrapper>
        <li>
          <span>
            <MenuItem to='/login'>Login </MenuItem>
          </span>
        </li>
        <li>
          <span>
          <MenuItem to='/registreren'>Registreren </MenuItem>
          </span>
        </li>        
    </MenuItemWrapper>  
  )     
}
