import styled from "styled-components";
import { NavLink} from 'react-router-dom';
import { Colors } from './colors' 

export const Nav = styled.nav`
  background-color: white;
  left: 0;
  right: 0;
  z-index: 99;
  min-height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const Logo = styled.img`
    height: 50px;
`

export const MenuWrapper = styled.div`
  margin-left: auto;
`

export const Menu = styled.ul`  
  list-style: none;
  margin: 0 -16px;
  @media (max-width: 772px) {    
      display: none;
  }    
`
export const MobileMenu = styled.ul`
  list-style: none;  
  display: block;
  @media (min-width: 772px) {    
      display: none;     
  }
`

export const MenuItem = styled(NavLink)`
  color: ${Colors.JbgBlue};;
  text-decoration: none;
  margin: 0 16px;
  font-weight: 600;
  font-size: 16px;
  transition: 0.4s; 
  padding: 0px;
  border-radius: 99px;  
  &.active{
    background-color: #00C2F5;
  }
  &:hover{
    background-color: #00C2F5;
  }
  @media (max-width: 772px) {    
      display: block;
      background-color: grey;
      max-width: 300px;
      margin: 0 auto 16px;
      border-radius: 0px;
      color: white;
  } 
`
export const MenuItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  //When on mobile
  @media (max-width: 772px) {    
      display: block;
      text-align: center;
      margin-bottom: 16px;
      padding: 12px 16px;      
  }      
`
export const HamburgerSpan = styled.span`  
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background-color: black;
  border-radius: 6px;
  z-index: 1;
  transform-origin: 0 0;
  transition: 0.4s;
  
  &.active{
    background-color: green;
  }  
`
export const Hamburger = styled.button`  
  display: block;
  position: fixed;
  right: 10px;
  z-index: 1;

  user-select: none;

  appearance: none;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  @media (min-width: 772px) {    
      display: none;      
  }
  &:hover ${HamburgerSpan}{
    background-color: #00C2F5;
  }  
`