import styled from 'styled-components'
import { useLocation } from 'react-router-dom';
import {verifyBrandwacht} from '../../services/officeStaff.service';
import { Colors } from '../../styledComponents/colors';
import { Container, BlueBackground} from '../../styledComponents/globalStyles';
import { getCertificatesSpecificUser } from '../../services/certificate.service';
import { useState, useEffect } from 'react';
import { verifyCertificate } from '../../services/certificate.service';
import { checkIfSpecificUserVerified } from '../../services/binnendienst.service';
import { getZzpByUser_id, verifyZzp } from '../../services/zzp.service';

const Button = styled.button`
    height: 40px;
    border: none;
    padding: 15px, 20px;
    background-color : ${Colors.JbgBlue};
    color: white;
    font-weight: bold;
    font-size: 15px;
    cursor: pointer;
    border-radius: 25px;
    justify-content: center;
    margin-left : 10px;
`
const Wrapper = styled.div`
    padding: 20px;    
    background-color: white;
    border-radius: 10px;    
`
const Alignment = styled.div`
    padding-top: 20px;    
`
const Table = styled.table`
border-spacing: 50px 0;
  border: 1px solid;
  color: black;
`

const Spacing = styled.div`
    height: 20px;
`

const SmallButton = styled.button`
    height: 15px;
    border: none;   
    background-color : ${Colors.JbgBlue};
    color: white;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
    border-radius: 20px;
    justify-content: center;
    width: 120%;
`

export default function ExpandBrandwacht() {
    const location = useLocation();
    const data = location.state;

    const [certificates, setCertificates] = useState([]); 
    const [userVerificationStatus, setUserVerificationStatus] = useState();
    const [UsersZzp, setUsersZzp] = useState('');
    const [isZzp, setIsZzp] = useState(false);
    const [useStateTrigger, setUseStatetrigger] = useState('');

    useEffect(() => {         
        getCertificates();
        getUsersVerificationStatus();
        getUsersZzp();
      },[useStateTrigger]);
    
      const getCertificates= async () => {
        const responseCertificates = await getCertificatesSpecificUser(data.id);     

        if(responseCertificates.status === 200)
        {
             setCertificates(responseCertificates.certificates);         
        }     
      }
      
      const getUsersVerificationStatus = async () => {
        const responseVerificationStatus = await checkIfSpecificUserVerified(data.id);    
        
        if(responseVerificationStatus.status === 200)
        {
            setUserVerificationStatus(responseVerificationStatus.verificationStatus);         
        }     
      }

      const getUsersZzp = async () => {
        const responseUsersZzp = await getZzpByUser_id(data.id);    
        
        if(responseUsersZzp.data.status === 200)
        {
          if(responseUsersZzp.Zzp === 'null'){
            setIsZzp(false);
          }
          else
          {
            setUsersZzp(responseUsersZzp.data.zzp);
            setIsZzp(true);           
          }         
        }     
      }
      

    const onClickVerifyBrandwacht =  async () => {    
        const response = await verifyBrandwacht(data.id);
        if(response.status === 200)
        {
          setUseStatetrigger('1');   
        }     
    }

    const onClickVerifyZzp = async() => {
      const response = await verifyZzp(UsersZzp.id);

      if(response.data.status === 200)
      {
        setUseStatetrigger('2');   
      }
    }

    const onClickVerifyCertificate= async (certificate_id) => {
      const response = await verifyCertificate(certificate_id);
      if(response.status === 200)
      {      
        setUseStatetrigger('3');    
      }     
  }

  const zzpLogic = () =>
  {
    if(isZzp)
    {
      if(UsersZzp?.verified === 0)
      {
        return (
        <div>
          <h3>
            ZZP gegevens die geverifieerd moeten worden:
          </h3>
          <div>
            ZZP-bedrijfsnaam: {UsersZzp.companyName}
          </div>
          <div>
            KVK-nummer: {UsersZzp.kvkNumber}
          </div>
          <Button onClick={onClickVerifyZzp}>
            ZZP-gegevens verificatie voldaan
          </Button>
        </div>
        )
      }
    }
    return null;
  }

  return (    
    <BlueBackground>           
        <Container>
            <Alignment>
                <Wrapper>
                    <h3>
                        {'Naam: ' + data.firstName + ' ' + data.lastName} 
                    </h3>
                    {userVerificationStatus? 
                    <div>
                        De gebruiker zijn identiteit is al geverifieerd.
                    </div>        
                    :
                    <div>
                        Is de brandwacht wie hij zegt te zijn?
                        <Button onClick={onClickVerifyBrandwacht}>
                            Persoon verificatie voldaan
                        </Button>
                    </div>                    
                    }
                    <Spacing/>
                    {
                      zzpLogic()
                    }
                    <Spacing/>
                    <Table>
                        <tr>
                            <th>Certificaat</th>
                            <th>Verloop datum</th>
                            <th>Geverifieerd</th>
                        </tr>
                        {certificates.map(item=>(
                        <tr>
                          <td>
                            {item.name}
                          </td>
                          <td>
                            {item.expiryDate}
                          </td>
                          <td>
                            {item.verified? 'Ja': <SmallButton onClick={() => onClickVerifyCertificate(item.id)}>Certificaat inorde</SmallButton>}
                          </td>
                        </tr>
                        ))}
                    </Table>
                </Wrapper>
            </Alignment>
        </Container>
    </BlueBackground>        
  )
}


