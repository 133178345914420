import styled from 'styled-components'
import { useState, useEffect } from 'react';
import AuthService from '../../services/auth.service';
import {
  BlueBackground,
  Container,
  WhiteWrapper
} from '../../styledComponents/globalStyles'
import { ErrorMessage, Input } from '../../styledComponents/form.styles'
import {BlueButton} from "../../styledComponents/button.styles";


const Label = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  text-align: left;
  max-width: 400px;
  margin-bottom: 10px;
  color: black;
  align-items: center;
`
const Section = styled.div`
  padding-top: 20px;
`
const Profile = () => {  
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [verifyNewPassword, setVerifyNewPassword] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setfirstName] = useState('');
    const [lastName, setlastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [city, setCity] = useState('');
    const [address, setAddress] = useState('');
    const [zipcode, setZipcode] = useState('');

    //Messages
    const [showDetailsChanged, setDetailsChanged] = useState(false);
    const [showPasswordChanged, setPasswordChanged] = useState(false);
    const detailsChanged = "Uw gegevens zijn succesvol gewijzigd";
    const passwordChanged = "Uw wachtwoord is succesvol gewijzigd";
    //Error messages
    const [showSomethingWentWrong, setShowSomethingWentWrong] = useState(false);
    const [showPasswordsDoNotMatch, setPasswordsDoNotMatch] = useState(false);
    const [showCurrentPasswordWrong, setCurrentPasswordWrong] = useState(false);
    const passwordsDoNotMatch  = 'De twee opgegeven wachtwoorden komen niet overeen';
    const somethingWentWrong = "Er gaat iets fout, probeer het later opnieuw!";
    const currentPasswordWrong = "Uw opgegeven wachtwoord is niet correct";

    useEffect(() => {
      getData();
    }, [])
    
    const getData = async () => {
      const response = await AuthService.getUserData(); 
      setfirstName(response.data.firstName); 
      setlastName(response.data.lastName); 
      setEmail(response.data.email); 
      setPhoneNumber(response.data.phoneNumber);
      setCity(response.data.city);
      setAddress(response.data.address);  
      setZipcode(response.data.zipcode);  
    } 
  

    const changePassword = async (e) => {
        e.preventDefault();
        //check if the new passwords match
        if(newPassword !== verifyNewPassword){ setPasswordsDoNotMatch(true);}

        const response = await AuthService.changePassword(currentPassword, newPassword);

        if(response.status === 200)
        {
          //the password is changed
          setPasswordChanged(true);
        }
        else if(response.status === 401)
        {
          //the old password is not right
          setCurrentPasswordWrong(true);
        }
        else
        {
          setShowSomethingWentWrong(true);
        }
    }
    const changeUserDetails = async (e) => {
        e.preventDefault();
               
        const response = await AuthService.changeAccountDetails(email, phoneNumber, city, address, zipcode);
        
        if(response.status === 200)
        {
          setDetailsChanged(true);
        }        
        else
        {
          setShowSomethingWentWrong(true);
        }
    }
  return (
    <BlueBackground>
      <Container>
        <WhiteWrapper>
        <h1>Uw profiel</h1>
        <Section>
          <h2>Account informatie</h2>
          <div>
            Voornaam: {firstName}
          </div>
          <div>
            Achternaam: {lastName}
          </div>
          <form onSubmit={changeUserDetails}>  
            <Label> 
                E-mail:
                <Input value = {email} onChange = {(e) => setEmail(e.target.value)}/> 
            </Label>
            <Label> 
                Telefoonnummer:
                <Input value = {phoneNumber} onChange = {(e) => setPhoneNumber(e.target.value)}/> 
            </Label>
            <Label> 
                Woonplaats:
                <Input value = {city} onChange = {(e) => setCity(e.target.value)}/> 
            </Label> 
            <Label> 
                Straatnaam en huisnummer:
                <Input value = {address} onChange = {(e) => setAddress(e.target.value)}/> 
            </Label>
            <Label> 
                Postcode:
                <Input value = {zipcode} onChange = {(e) => setZipcode(e.target.value)}/> 
            </Label> 
            {showDetailsChanged?  detailsChanged : null}
            <BlueButton fontSize={"20px"} height={"40px"} width={"25vw"} minWidth={"17vh"}>
              Wijzig gegevens
            </BlueButton>
          </form>
        </Section>
        <Section>
          <h2>Verander uw wachtwoord</h2>
          <form onSubmit={changePassword}>                
          <Label>
            Huidig wachtwoord: 
            <Input type = "password" value = {currentPassword} onChange = {(e) => setCurrentPassword(e.target.value)} placeholder= 'Huidig wachtwoord'/>
          </Label>
          <Label>
            Nieuw wachtwoord:
            <Input type = "password" value = {newPassword} onChange = {(e) => setNewPassword(e.target.value)} placeholder= 'Nieuw wachtwoord'/>
          </Label>
          <Label>
            Bevestig wachtwoord:
            <Input type = "password" value = {verifyNewPassword} onChange = {(e) => setVerifyNewPassword(e.target.value)} placeholder= 'Bevestig wachtwoord'/>
          </Label> 
          <ErrorMessage>{showCurrentPasswordWrong?  currentPasswordWrong : null}</ErrorMessage>
          <ErrorMessage>{showPasswordsDoNotMatch?  passwordsDoNotMatch : null}</ErrorMessage>
          {showPasswordChanged?  passwordChanged : null}
            <BlueButton fontSize={"20px"} height={"40px"} width={"25vw"} minWidth={"17vh"}>
              Wijzig wachtwoord
            </BlueButton>
          </form>
          <ErrorMessage>{showSomethingWentWrong?  somethingWentWrong : null}</ErrorMessage>  
          </Section>
        </WhiteWrapper>
      </Container>      
    </BlueBackground>
  )
}
export default Profile