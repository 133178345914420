import styled from 'styled-components'
import React, { useState } from 'react';
import AuthService from '../../../services/auth.service';
import { useSelector } from 'react-redux';
import { BlueBackground } from '../../../styledComponents/globalStyles';
import { Button, ButtonWrapper } from '../../../styledComponents/form.styles';

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`
const Wrapper = styled.div`
    padding: 20px;
    width: 40%;
    background-color: white;
    border-radius: 10px;
`
const Form = styled.form`
    display: flex;
    flex-direction: column;
`
const Title = styled.h1`
    text-align: center;
`
const Input = styled.input`
    flex: 1;
    min-width: 40%;
    margin: 10px 0px;
    padding: 10px;
    border-radius: 10px;
`

const CreateNewEmployee = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');    
    const [show, setShow] = useState(false);     
    const [password, setPassword] = useState(''); 
    const [address, setAddress] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [city, setCity] = useState('');

    const companyId = useSelector(state => state.selectedCompany);
    
    const handleSubmit = async (e) => {
        e.preventDefault();        
        
        const response = await AuthService.registerEmployee(firstName, lastName, email, phoneNumber, companyId.companyId, city, address, zipcode);
        if(response.data.status === 200){
            setPassword(response.data.password);                 
            setShow(true);            
        }       
    }

  return (
    <BlueBackground>       
        <Container>        
            <Wrapper>
                <Title>Registreer een nieuw personeelslid</Title>
                <Form onSubmit={handleSubmit}>
                    <Input value = {firstName} onChange = {(e) => setFirstName(e.target.value)} placeholder= 'Voornaam'/>
                    <Input value = {lastName} onChange = {(e) => setLastName(e.target.value)} placeholder= 'Achternaam'/>                
                    <Input value = {email} onChange = {(e) => setEmail(e.target.value)} placeholder= 'Email'/>
                    <Input value = {phoneNumber} onChange = {(e) => setPhoneNumber(e.target.value)} placeholder= 'Telefoonnummer'/>
                    <Input value = {city} onChange = {(e) => setCity(e.target.value)} placeholder= 'Woonplaats'/>
                    <Input value = {address} onChange = {(e) => setAddress(e.target.value)} placeholder= 'Straatnaam en huisnummer'/>
                    <Input value = {zipcode} onChange = {(e) => setZipcode(e.target.value)} placeholder= 'Postcode'/>                                
                    <ButtonWrapper>
                        <Button>Beheerder registreren</Button>
                    </ButtonWrapper>
                </Form>
                <div>
                    {show? <p>Hieronder staan de login gegevens van het nieuwe personeelslid: <br/>Email:  {' ' + email} <br/> Wachtwoord: {' ' + password}</p> : null}
                </div>
            </Wrapper>
        </Container>
    </BlueBackground>  
  )
}
export default CreateNewEmployee;
