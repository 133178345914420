import styled from "styled-components";
import {Colors} from "./colors";

export const BlueButton = styled.button`
    height: ${props => props.height};
    width: ${props => props.width};
    min-height: ${props => props.minHeight};
    min-width: ${props => props.minWidth};
    border: none;
    padding: 15px, 20px;
    background-color : ${Colors.JbgBlue};
    color: white;
    font-weight: bold;
    font-size: ${props => props.fontSize || '16px'};
    cursor: pointer;
    border-radius: 25px;
    justify-content: center;
`

export const GrayButton = styled.button`
    height: ${props => props.height};
    width: ${props => props.width};
    border: none;
    padding: 15px, 20px;
    background-color : gray;
    color: white;
    font-weight: bold;
    font-size: ${props => props.fontSize || 16}px;
    cursor: pointer;
    border-radius: 25px;
    justify-content: center;
`
export const PaginationButton = styled.button`
  width: 50px;
  height: 50px;
  margin-right: 10px;
  &.active{
    background-color: grey;
  }
`