import Content from './info/Content'
import Register from './info/Register'


export default function Home() {
    return (
      <div>
        <Content/>
        <Register/>
      </div>  
    )
  }
  