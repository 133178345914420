import loginReducer  from "./login.reducer";
import { combineReducers, createStore} from "redux";
import selectCompanyReducer from "./selectCompany.reducer";
import rolesReducer from "./roles.reducer";
import googleAgendaReducer from "./googleAgenda.reducer";

const rootReducer = combineReducers({
    login: loginReducer,
    selectedCompany : selectCompanyReducer, 
    roles: rolesReducer, 
    isGoogleAgendaLinked: googleAgendaReducer,  
})

const store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());


export default store