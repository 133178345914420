import axiosInstance from "./axiosInstance";

export async function getNotFullyVerifiedBrandwachten() {
    const response = await axiosInstance.get('api/getAllNotFullyVerifiedBrandwachten');
    return response;
} 

export async function checkIfSpecificUserVerified(user_id) {
    const response = await axiosInstance.post('api/checkIfSpecificUserVerified', {user_id: user_id});
    return response.data;
} 

export async function registerBrandwachtByEmployee(firstName, lastName, email, phoneNumber, address, zipcode, city, isZzp, companyName, kvkNumber) {
    const response = await axiosInstance.post('api/registerBrandwachtByEmployee', {firstName: firstName,
        lastName: lastName, email: email, phoneNumber: phoneNumber, 
        address: address, zipcode: zipcode, city: city, isZzp: isZzp, companyName: companyName, kvkNumber: kvkNumber});
    return response;
} 



