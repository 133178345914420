import { BlueBackground, Container, WhiteWrapper, RowOfElements} from "../../../styledComponents/globalStyles";
import { getNotPublishedJobs } from "../../../services/jobs.service";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate, Link } from "react-router-dom";
import { BlueButton } from "../../../styledComponents/button.styles";
import {ResponsiveTableDiv} from "../../../styledComponents/form.styles";

const Table = styled.table`
  border-spacing: 50px 0;
  border: 1px solid;
  color: black;
`

const ManageJob = () =>{
    const [notPublishedJobs, setNotPublishedJobs] = useState([]);

    const fetchNotPublishedJobs = async () => {        
        const response = await getNotPublishedJobs();
        if(response.data.status === 200)
        {
          setNotPublishedJobs(response.data.jobs);          
        }     
    }

    useEffect(() => {       
        fetchNotPublishedJobs();        
    },[]);

    const navigate = useNavigate();
    return (
        <BlueBackground>
            <Container>
                <WhiteWrapper>              
                    <RowOfElements>
                        <h1>Opdracht aanvragen</h1>
                        <div>
                            <Link to="createjob">
                                <BlueButton height="40px"> Opdracht aanmaken</BlueButton>
                            </Link>
                            <Link to="JobsToEdit">
                                <BlueButton height="40px">Opdracht bewerken</BlueButton>
                            </Link>
                        </div>
                    </RowOfElements>
                    <ResponsiveTableDiv>
                        <Table>
                        {notPublishedJobs.length?
                            <tr>
                                <th>
                                    Bedrijfsnaam
                                </th>
                                <th>
                                    Datum van aanvraag
                                </th>
                            </tr>
                        :
                        null
                            }

                        {notPublishedJobs.length? notPublishedJobs.map(item=>(
                                <tr>
                                    <td>
                                        {item.company.name}
                                    </td>
                                    <td>
                                        {item.created_at.slice(0, -17)}
                                    </td>
                                    <BlueButton fontSize={"13px"} width={"130px"} height={"20px"} onClick={() => {navigate('/postJob', {state:item})}}>
                                        Bekijk aanvraag
                                    </BlueButton>
                                </tr>
                            ))
                            :
                            <h3>
                                Er staan geen aanvragen open.
                            </h3>
                            }
                        </Table>
                    </ResponsiveTableDiv>
                </WhiteWrapper>  
            </Container>
        </BlueBackground>
    );
}
export default ManageJob;