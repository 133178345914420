import styled from 'styled-components'
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BlueBackground, Container } from '../../../styledComponents/globalStyles'
import { Button } from '../../../styledComponents/form.styles';
import { useLocation } from 'react-router-dom';
import { getShiftsByJobId, getRevenueByJobId, publishJob } from '../../../services/jobs.service';

const Wrapper = styled.div`
    padding: 20px;    
    background-color: white;
    border-radius: 10px;    
`
const FormWrapper = styled.div`
    padding-top: 20px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    width: 90%;
    background-color: white;
    border-radius: 10px;
`
const Form = styled.form`
    padding: 5px;
`
const Title = styled.h1`

`

const Header = styled.h4`

`
const BoxForSpacing = styled.div`
    padding-top: 20px;
`
const Input = styled.input`
    flex: 1;
    min-width: 40%;
    margin: 0px 10px 0px 0px;
    padding: 10px;
    border-radius: 5px;
`
const BigInput = styled.input`
    flex: 1;
    min-width: 40%;
    margin: 0px 10px 0px 0px;
    padding: 10px;
    border-radius: 5px;
`
const Salary = styled.input`
    width: 50px;    
`

const PostJob = () =>{
    const location = useLocation();
    const job = location.state;
    const navigate = useNavigate();

    const [shifts, setShifts] = useState([]);
    const [salary, setSalary] = useState('');
    const [revenue, setRevenue] = useState('');

    const fetchShifts = async () => {        
        const response = await getShiftsByJobId(job.id);
        if(response.data.status === 200)
        {
          setShifts(response.data.shifts);          
        }     
    }

    const fetchRevenue = async () => {        
        const response = await getRevenueByJobId(job.id);
        if(response.data.status === 200)
        {
          setRevenue(response.data.revenue);         
        }     
    }

    const handleSubmit = async (e) => {
        e.preventDefault(); 
        const response = await publishJob(job.id, salary);
        if(response.data.status === 200)
        {
            navigate('/')
        }  
    }

    useEffect(() => {       
        fetchShifts();
       fetchRevenue();        
    },[]);

  return (    
        <BlueBackground>   
            <Container> 
            <FormWrapper>       
                <Wrapper>
                    <Form onSubmit={handleSubmit}>
                        <Title>Opdracht aanvraag</Title>                        
                        <BoxForSpacing/>        
                        <Header>
                            Titel van de opdracht
                        </Header>
                        <Input value = {job.title}/>
                        
                        <BoxForSpacing/> 
                        <Header>De locatie:</Header>
                            <Input value = {job.city}/>                         
                            <Input value = {job.address}/>
                            <Input value = {job.zipcode}/>                            
                        
                        <BoxForSpacing/>
                        <Header>
                            Voeg hieronder de verschillende diensten toe dat uw klus heeft met per dienst de tijden en de benodigde certificaten.
                        </Header>
                        {shifts.map((singleShift) => (
                            <div>
                            <BoxForSpacing/> 
                            <div style={{outline: "2px dashed black"}}>                
                                <Header>Datum en tijd:</Header>
                                <div>
                                    Selecteer een datum:                           
                                    <Input name="date" value = {singleShift.date} type='date'/>
                                </div>
                                <div>
                                    Selecteer een begintijd:
                                    <Input name="startTime" value = {singleShift.startTime}  type ='time'/>
                                </div>
                                <div>
                                    Selecteer een eindtijd:
                                    <Input name="endTime" value = {singleShift.endTime}  type ='time'/>                
                                </div>
            
                                <BoxForSpacing/>                                                                                   
                                    <div>
                                        <table>
                                            <tbody>
                                                <tr><th>Benodigde certificaten:</th></tr>
                                            
                                                    {                                                       
                                                       singleShift.certificate_types.map(certificate => (
                                                        <tr><td> {certificate.name}</td></tr> 
                                                       ))
                                                    }
                                            </tbody>
                                        </table>    
                                    </div>     
                                <BoxForSpacing/>
                            </div>
                        </div>
                        ))}
                        <Header>
                            Vergoeding die JBG ontvangt:  
                        </Header>
                        <div>                               
                            € <Salary value = {revenue} size="20"/> Per uur 
                        </div>                       
                        <BoxForSpacing/>
                        <Header>
                            De opdracht omschrijving:
                        </Header>
                        <BigInput value = {job.description}/> 
                        <BoxForSpacing/>
                        <Header>
                            Vergoeding die de brandwacht ontvangt:  
                        </Header>
                        <div>                               
                            € <Salary value = {salary} onChange = {(e) => setSalary(e.target.value)} placeholder='--' size="20"/> Per uur 
                        </div>                  
                        <BoxForSpacing/>
                        <Button>Opdracht plaatsen</Button>                                               
                    </Form>           
                </Wrapper>
            </FormWrapper>    
            </Container>
        </BlueBackground>            
    ) 
}
export default PostJob;