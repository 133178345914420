import { useNavigate } from 'react-router-dom';
import {
  BlueBackground,
  Container,
  MenuBox
} from "../../styledComponents/globalStyles";

const AdminPanel = () => { 
    const navigate = useNavigate();
    const handleClick = async () => {
        navigate('/AdminPanel/Personeel')        
    }

  return (
      <BlueBackground>
        <Container>
            <h1 style={{color: "white"}}>Admin panel</h1>
            <MenuBox onClick={handleClick}>
                Personeel
            </MenuBox>
        </Container>
      </BlueBackground>
  )
}
export default AdminPanel