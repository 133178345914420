import axios from "axios";

const axiosInstance = axios.create({
    baseURL: 'https://proxy.brandwacht.app/'
    //baseURL: 'http://localhost:8000/'
});

axiosInstance.interceptors.request.use((config) => {    
      config.headers['Authorization'] = `Bearer ${JSON.parse(localStorage.getItem('user')).token}`;
          return config;
      },
      error => {
          return Promise.reject(error);
      }
  );

  export default axiosInstance;

