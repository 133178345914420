import styled from 'styled-components'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import EuroSymbolOutlinedIcon from '@mui/icons-material/EuroSymbolOutlined';
import { useLocation } from 'react-router-dom';
import { BlueBackground } from '../../styledComponents/globalStyles';
import { getUsersOnJob } from "../../services/jobs.service";
import React, { useState, useEffect } from 'react';

const Container = styled.div`
    width: 100%;    
    display: flex;    
    justify-content: center;
    padding-top: 20px;
`
const Wrapper = styled.div`
    padding: 20px;
    width: 80%;
    background-color: white;
    border-radius: 10px;
`

const LocationDate = styled.div`
  display: flex;
  justify-content: space-between;`

export default function ExpandJobCompanyAdmin() {
    const location = useLocation();
    const data = location.state;
    const [userData, setData] = useState([]) 

    useEffect(() => {
        getData();
    }, []) 
    
    const getData = async (e) => {
        
        const response = await getUsersOnJob(data.id);        
        
        if(response.data.status === 200)
        {
            console.log(response.data.users)
            setData(response.data.users);      
        }
    }

  return (     
    <BlueBackground>      
        <Container>
            <Wrapper>
            <div><h2>{data.title}</h2></div>
                <LocationDate><div><LocationOnOutlinedIcon />{data.city}</div> <div><CalendarMonthOutlinedIcon/>{data.date}</div> <div><AccessTimeOutlinedIcon/>{data.startTime}-{data.endTime}</div></LocationDate>
                <div><EuroSymbolOutlinedIcon/>{data.salary} per uur</div>     
                {data.certificates}
                {data.description}  
                <div>                 
                Deze opdracht is aangenomen door:
                {userData.map(user => user.firstName + ' ' + user.lastName + ', ')}             
                </div>           
            </Wrapper>
        </Container>  
    </BlueBackground> 
  )
}