import styled from "styled-components"

const Title = styled.h1`
  color: white;
`
const Text = styled.p`
  color: white;
`
const Link = styled.a`
  color: white;
`

export default function CompanyAdminCreatedHints() {
    return (
      <div>
        <Title>
            Welkom!
        </Title>
        <Text>
            Met u account kunt u nu de volgende stappen ondernemen:<br/>
            1. Bekijk openstaande opdracht onder het kopje "Openstaande opdrachten". Of klik <Link href='https://brandwacht.app/opdrachten'> hier</Link><br/>
        </Text>          
     </div>
    )
  }