import axiosInstance from "./axiosInstance";

export async function createCompany(companyName, kvkNumber, phoneNumber, address, zipcode, city) {
    const response = await axiosInstance.post('api/create-company', {companyName, kvkNumber, phoneNumber, address, zipcode, city})
    return response;
} 

export async function getMyCompanies() {    
    const response = await axiosInstance.get('api/get-my-companies');
    return response;
}

export async function getEmployees(companyId) {
    const response = await axiosInstance.post('api/getEmployees',{id: companyId});
    return response;
} 

export async function deleteEmployee(userId, companyId) {
    const response = await axiosInstance.post('api/deleteEmployee', {userId, companyId});
    return response;
} 
    
