const initState = {
  isLoggedIn: false,
  isVerified: false,     
}

const loginReducer = (state = initState, action) => {
    switch(action.type){
        case 'LOGIN':
            return {...state, isLoggedIn:action.payload}
        case 'VERIFIED':
            return {...state, isVerified:action.payload}            
        default: return state;
    }
}
export default loginReducer



