import authService from '../../services/auth.service';
import {MenuItem, MenuItemWrapper} from "../../styledComponents/navbar.styles"

export default function BrandwachtLinks() {

  const handleClick = async (e) => {    
    authService.logout();       
};
  return (    
      <MenuItemWrapper>             
        <li>
          <span>
          <MenuItem onClick={handleClick} to='/'>Uitloggen </MenuItem>
          </span>
        </li>        
      </MenuItemWrapper>   
  )
}
