import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CalendarMonthOutlinedIcon
  from "@mui/icons-material/CalendarMonthOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import EuroSymbolOutlinedIcon from "@mui/icons-material/EuroSymbolOutlined";
import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";

const Card = styled.div`
  margin: 20px;
  padding: 20px;
  min-width: 170px;
  min-height: 200px;
  background-color: white;
  border-radius: 0.5em;  
  cursor: pointer;
  font-size: calc(30px + 0.390625vw);
  position: relative ;
`
const LocationDate = styled.div`
  display: flex;
  justify-content: space-between;
`
const FooterRight = styled.div`  
  position: absolute;
  bottom: 0;
  right: 5px;
`
const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: calc(15px + 0.390625vw);
`

const JobCard = ({item}) => {
  return(
      <Card key = {item.job.id}>
        <div><h2>{item.job.title}</h2></div>
        <LocationDate>
          <div>
            <LocationOnOutlinedIcon />
            {item.job.city + ' ' + item.distance + ' km'}
          </div>
        </LocationDate>
        {item.job.shifts.map((shift) => (
            <div>
              <LocationDate>
                <div>
                  <CalendarMonthOutlinedIcon/>
                  {
                    shift.date
                  }
                </div>
                <div>
                  <AccessTimeOutlinedIcon/>
                  {
                    shift.startTime.slice(0, -3)}-{shift.endTime.slice(0,-3)
                }
                </div>
              </LocationDate>
              <div>
                Certificaten:
                {shift.certificate_types.map((certificate) =>(
                    certificate.name + ' '
                ))}
              </div>
            </div>
        ))}
        <div>
          <EuroSymbolOutlinedIcon/>{item.job.salary} per uur
        </div>
        <div>
          Beschrijving:
          {item.job.description}
        </div>
        <FooterRight>
          <StyledLink to='/jobdetails' state={item}>Klik om meer te lezen</StyledLink>
        </FooterRight>
      </Card>
  )
}
export default JobCard;