import { getMyCompanies} from "../../../services/companies.service";
import Company from './Company';
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  BlueBackground,
  Container,
  RowOfElements
} from "../../../styledComponents/globalStyles";
import {GrayButton} from "../../../styledComponents/button.styles";


const Titel = styled.h1`
  color: white;
`
const ButtonWrapper = styled.div`
  align-self: flex-end;
`
const CompanyWrapper = styled.div`
    display: inline-flex;
`
const MyCompanies = () => {
  const selectedCompanyId = useSelector(state => state.selectedCompany);
  const navigate = useNavigate();
  const notInitialRender = useRef(false);
  const [data, setData] = useState([]);   
  const [noCompanyFound, setNoCompanyFound] = useState(true);
  
  const getData = async () => {
    const response = await getMyCompanies();
    if(response.data.status === 200)
    {
      if(response.data.companies.length === 0)
      {
        setNoCompanyFound(true);
      }
      else
      {
        setData(response.data.companies);
        setNoCompanyFound(false);
      }   
    }     
  } 

    useEffect(() => {
      getData();
      if(notInitialRender.current) {
          //Get user data each time react is loaded      
          navigate('/bedrijfsbeheer',  { state: {selectedCompanyId} });
            
        } else {
          notInitialRender.current = true;
        }        
      }, [selectedCompanyId]) 

  return (
    <BlueBackground>
      <Container>
        {noCompanyFound? <NoCompaniesFound/> : <Companies companies={data}/>}
      </Container>
    </BlueBackground>
  )
}

const Companies = ({companies}) => {    
  return (
    <Container>
        <RowOfElements>
          <Titel>Je bedrijven</Titel>
            <GrayButton width={"150px"}>Bedrijf aanmaken</GrayButton>
        </RowOfElements>
      
        {companies.map(item=>(
          <CompanyWrapper>
              <Company item = {item} key = {item.id}/>
          </CompanyWrapper>
        ))}
    </Container>
  )
}

const NoCompaniesFound = () => {    
  return (  
    <div>  
      <Titel>U heeft nog geen bedrijf in ons systeem, maak een bedrijf aan</Titel>
      <ButtonWrapper>
        <Link to='/bedrijf-aanmaken'>
          <GrayButton>Bedrijf aanmaken</GrayButton>
        </Link>
      </ButtonWrapper>
    </div>        
  )
}
export default MyCompanies