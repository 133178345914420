import axiosInstance from "./axiosInstance";


export async function getAdmins() {
    const response = await axiosInstance.get('api/getadmins');
    return response;
} 

export async function deleteAdmin(userId) {
  const response = await axiosInstance.post('api/deleteAdmin', {userId: userId});
  return response;
} 




