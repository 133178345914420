import styled from 'styled-components'


const Container = styled.div`
  margin-top: auto; 
  background-color: #061532;
  height: 200px;
  color: white;
`

export default function Footer() {
  return ( 
    <Container> 
      Hier komt footer informatie
    </Container>   
  )     
}
