import Landing from "./pages/landing/Landing";
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import JobOverview from "./pages/jobs/overviewJobs/JobOverview";
import Login from "./pages/login/Login";
import RegisterCompanyAdmin from "./pages/register/RegisterCompanyAdmin";
import Navbar from './components/navbar/Navbar'
import JobDetails from "./pages/jobs/JobDetails";
import MyJobs from "./pages/jobs/MyJobs";
import Profile from "./pages/profile/Profile";
import CompanyJobs from "./pages/companyCreatedJobs/CompanyJobs"
import ExpandJobCompanyAdmin from "./pages/jobs/ExpandJobCompanyAdmin";
import AuthService from "../src/services/auth.service";
import React, { useEffect } from 'react';
import CreateCompany from "./pages/company/manageMyCompanies/CreateCompany";
import MyCompanies from "./pages/company/manageMyCompanies/MyCompanies";
import ChooseUserRole from "./pages/register/ChooseUserRole";
import RegisterBrandwacht from "./pages/register/RegisterBrandacht";
import OverviewNotVerifiedBrandwachten from "./pages/verifyUsers/OverviewNotVerifiedBrandwachten";
import ExpandBrandwacht from "./pages/verifyUsers/ExpandBrandwacht";
import ProtectedRoutes from "./ProtectedRoutes";
import AdminPanel from "./pages/admin/AdminPanel";
import AdminPanelEmployees from "./pages/admin/AdminPanelEmployees";
import CreateNewAdmin from "./pages/admin/CreateNewAdmin";
import EditAdmin from "./pages/admin/EditAdmin";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import CreateJob from "./pages/jobs/CreateJob";
import ManageCompany from "./pages/company/manageMyCompanies/ManageCompany";
import Employees from "./pages/company/manageCompanyEmpolyees/Employees";
import ManageCompanyCreateNewEmployee from "./pages/company/manageCompanyEmpolyees/CreateNewEmployee";
import EditEmployee from "./pages/company/manageCompanyEmpolyees/EditEmployee";
import AddCalendar from "./pages/findAvailableBrandwacht/AddCalendar";
import FindAvailableBrandwacht from "./pages/findAvailableBrandwacht/FindAvailableBrandwacht";
import Home from "./pages/home/Home";
import Footer from "./components/footer/Footer";
import ForgotPassword from "./pages/resetPassword/ForgotPassword";
import GlobalStyle from './styledComponents/globalStyles';
import OverviewCertificates from "./pages/certificates/OverviewCertificates";
import ManageCertificate from "./pages/certificates/ManageCertificate";
import RegisterUsers from "./pages/inviteBrandwachten/RegisterUsers";
import ExpiringCertificates from "./pages/certificates/ExpiringCertificates";
import CreateCertificateType from './pages/certificates/CreateCertificateType';
import ManageJob from "./pages/jobs/manageJobsBackOffice/ManageJob";
import PostJob from "./pages/jobs/manageJobsBackOffice/PostJob";
import JobsToEdit from "./pages/jobs/manageJobsBackOffice/JobsToEdit";
import CreateJobOfficeStaff from "./pages/jobs/manageJobsBackOffice/CreateJobOfficestaff";
import EditJob from "./pages/jobs/manageJobsBackOffice/EditJob";

const App = () => {
  useEffect(() => {
    //Get user data each time react is loaded      
    AuthService.getUserData();
  }, []) 

  
  return (    
    <Router>
      <GlobalStyle/>
      <Navbar/>       
      <Routes>
        <Route path='/' element= {<Home/>}/>
        {/* Login, Register and PasswordReset*/}
        <Route path='/login' element= {<Login/>}/>
        <Route path='/registreren' element= {<ChooseUserRole/>}/>
        <Route path='/RegistrerenBedrijfsbeheerder' element= {<RegisterCompanyAdmin/>}/>
        <Route path='/RegistrerenBrandwacht' element= {<RegisterBrandwacht/>}/>
        <Route path='/ResetPassword' element= {<ResetPassword/>}/>
        <Route path='/wachtwoordvergeten' element= {<ForgotPassword/>}/>  
        {/* Protected routes */}
        <Route element = {<ProtectedRoutes/>}>
          <Route path='/Landing' element= {<Landing/>}/>

          {/* Company admin */}
          <Route path='/bedrijf-aanmaken' element= {<CreateCompany/>}/>
          <Route path='/bedrijven' element= {<MyCompanies/>}/>
          <Route path='/opdracht-toevoegen' element= {<CreateJob/>}/>
          <Route path='/bedrijfsbeheer' element= {<ManageCompany/>}/>
          <Route path='/bedrijfsbeheer/personeel' element= {<Employees/>}/>
          <Route path='/bedrijfsbeheer/nieuwpersoneel' element= {<ManageCompanyCreateNewEmployee/>}/>
          <Route path='/bedrijfsbeheer/bewerkpersoneel' element= {<EditEmployee/>}/>
          <Route path='zoekBeschikbareBrandwacht' element= {<FindAvailableBrandwacht/>}/>
          <Route path='/companyjobs' element= {<CompanyJobs/>}/>
          <Route path='/expandjobadmin' element= {<ExpandJobCompanyAdmin/>}/> 
          

          {/* Brandwacht */}
          <Route path='/opdrachten' element= {<JobOverview/>}/>
          <Route path='/mijn-opdrachten' element= {<MyJobs/>}/>
          <Route path='/agendaToevoegen' element= {<AddCalendar/>}/>
          <Route path='/certificaten' element= {<OverviewCertificates/>}/>

          {/* Binnendienst */}
          <Route path='/nietGeverifieerdeBrandwachten' element= {<OverviewNotVerifiedBrandwachten/>}/>
          <Route path='/expandNietGeverifieerdeBrandwachten' element= {<ExpandBrandwacht/>}/>
          <Route path='/certificatenBeheer' element= {<ManageCertificate/>}/>
          <Route path='/certificatenBeheer/certificaatTypeAanmaken' element= {<CreateCertificateType/>}/>
          <Route path='/registrerengebruikers' element= {<RegisterUsers/>}/>
          <Route path='/certificatenBeheer/certificatenmaandtotverloop' element= {<ExpiringCertificates/>}/>
          <Route path='/opdrachtenBeheer' element= {<ManageJob/>}/>
          <Route path='/postJob' element= {<PostJob/>}/>
          <Route path='opdrachtenBeheer/JobsToEdit' element= {<JobsToEdit/>}/>
          <Route path='opdrachtenBeheer/JobsToEdit/EditJob' element= {<EditJob/>}/>
          <Route path='opdrachtenBeheer/createjob' element= {<CreateJobOfficeStaff/>}/>
          

          {/* Binnendienst - Admin */}
          <Route path='/AdminPanel' element= {<AdminPanel/>}/>
          <Route path='/AdminPanel/Personeel' element= {<AdminPanelEmployees/>}/>
          <Route path='/AdminPanel/NieuweBeheerder' element= {<CreateNewAdmin/>}/>
          <Route path='/AdminPanel/EditAdmin' element= {<EditAdmin/> }/>          
          
          {/* Everyone */}
          <Route path='/profile' element= {<Profile/>}/> 
          <Route path='/jobdetails' element= {<JobDetails/>}/>                            
        </Route>
      </Routes>      
      <Footer/>       
    </Router>
     
  );
};

export default App;