import styled from 'styled-components'
import { useState, useEffect, useRef } from 'react';
import {getMyCompanies} from '../../services/companies.service';
import {createJob} from '../../services/jobs.service';
import { useNavigate } from 'react-router-dom';
import { BlueBackground, Container } from '../../styledComponents/globalStyles'
import NoCompaniesFound from '../../components/common/NoCompaniesFound';
import { Button , ErrorMessage} from '../../styledComponents/form.styles';
import { getCertificateTypes } from '../../services/certificate.service';
import {BlueButton} from "../../styledComponents/button.styles";

const Wrapper = styled.div`
    padding: 20px;    
    background-color: white;
    border-radius: 10px;    
`
const FormWrapper = styled.div`
    padding-top: 20px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    width: 90%;
    background-color: white;
    border-radius: 10px;
`
const Form = styled.form`
    padding: 5px;
`
const BoxForSpacing = styled.div`
    padding-top: 20px;
`
const Input = styled.input`
    flex: 1;
    min-width: 40%;
    margin: 0px 10px 0px 0px;
    padding: 10px;
    border-radius: 5px;
`
const BigInput = styled.input`
    flex: 1;
    min-width: 40%;
    margin: 0px 10px 0px 0px;
    padding: 10px;
    border-radius: 5px;
`
const Salary = styled.input`
    width: 50px;    
`

export default function CreateJob() {
    const [noCompanyFound, setNoCompanyFound] = useState(true);
    const [companies, setCompanies] = useState([]);
    const [certificateTypes, setCertificateTypes] = useState([]);
    //Selected company to create job for, with the first one as default
    const [selectedCompanyId, setSelectedCompanyId] = useState('');
     

    useEffect(() => { 
       getCompanies();
       fetchCertificateTypes();       
      }, []) 

      const isFirstRender = useRef(true);

      useEffect(() => { 
        if(isFirstRender.current)
        {
            isFirstRender.current = false;
            return;
        }        
        setSelectedCompanyId(companies[0].id);
       }, [companies]) 

    
    const getCompanies = async () => {
        const response = await getMyCompanies();
        
        if(response.data.status === 200)
        {            
            if(response.data.companies.length === 0)
            {
                setNoCompanyFound(true);
            }
            else
            {
                setCompanies(response.data.companies); 
                setNoCompanyFound(false);                                          
            }                                         
        }
    }

    const fetchCertificateTypes = async () => {
        const response = await getCertificateTypes();
        
        if(response.status === 200)
        {              
            setCertificateTypes(response.certificateTypes);           
        }
    } 

    //Form data
    const [title, setTitle] = useState('');    
    const [address, setAddress] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [city, setCity] = useState('');    
    const [revenue, setRevenue] = useState('');
    const [description, setDescription] = useState('');

    //Shift logic
    const [shiftList, setShiftList] = useState([]);
        
    const addShift = () => {        
        setShiftList((prev) => [
          ...prev,
          {
            id: shiftList.length,
            date: '',
            startTime: '',
            endTime: '',
            addedCertificates: [],
            selectedCertificate: certificateTypes[0],  //Setting default        
          },
        ]);               
    };

    //Error messages    
    const invalidAddress  = 'Dit is geen geldig adres volgens ons systeem';
    
    //Error states
    const [showInvalidAddress, setInvalidAddress] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        
        e.preventDefault();         
         const response = await createJob(title, address, zipcode, city, shiftList, revenue, description, selectedCompanyId);

        if(response.data.status === 200)
        {            
            navigate('/Landing');
        }
        else
        {
            //Something is wrong
            if(response.data === 'Address not found')
            {
                setInvalidAddress(true);
            }
        }
    }

    const handleChangeCompany = (event) => {
        setSelectedCompanyId(event.target.value);        
      };

    const addCertificate = (index) => {        
        const list = [...shiftList];        
        list[index].addedCertificates.push(list[index]['selectedCertificate']);
        setShiftList(list);        
    }
 
    const handleChangeShift  = (e, index) => {
        const {name, value} = e.target;
        const list = [...shiftList];
        list[index][name] = value;
        setShiftList(list);    
    }
    
    const handleChangeSelectedCertificate = (e, index) => {
        const list = [...shiftList];
        let obj = JSON.parse(e.target.value);
        list[index]['selectedCertificate'] = obj;
    }

  return (
    <div>        
        {noCompanyFound? <NoCompaniesFound/>
        :
        <BlueBackground>   
            <Container> 
            <FormWrapper>       
                <Wrapper>
                    <Form onSubmit={handleSubmit}>
                        <h1>Opdracht toevoegen</h1>
                        <div>
                            Selecteer je bedrijf: 
                            <select onChange={handleChangeCompany}>
                                {companies.map(company => (
                                    <option value={company.id}>
                                        {company.name}
                                    </option>
                            ))}
                            </select>                            
                        </div>
                        <BoxForSpacing/>        
                        <h4>
                            Titel van de opdracht
                        </h4>
                        <Input value = {title} onChange = {(e) => setTitle(e.target.value)} placeholder= 'Titel'/>
                        
                        <BoxForSpacing/> 
                        <h4>De locatie:</h4>
                            <Input value = {city} onChange = {(e) => setCity(e.target.value)} placeholder= 'Plaats'/>                         
                            <Input value = {address} onChange = {(e) => setAddress(e.target.value)} placeholder= 'Adres'/>
                            <Input value = {zipcode} onChange = {(e) => setZipcode(e.target.value)} placeholder= 'Postcode'/>
                            <ErrorMessage>{showInvalidAddress?  invalidAddress : null}</ErrorMessage>
                        
                        <BoxForSpacing/>
                        <h4>
                            Voeg hieronder de verschillende diensten toe dat uw klus heeft met per dienst de tijden en de benodigde certificaten.
                        </h4>
                        {shiftList.map((singleShift, index) => (
                            <div>
                            <BoxForSpacing/> 
                            <div style={{outline: "2px dashed black"}}>                
                                <h4>Datum en tijd:</h4>
                                <div>
                                    Selecteer een datum:                           
                                    <Input name="date" value = {singleShift.date} onChange = {(e) => handleChangeShift(e, index)} type='date'/>
                                </div>
                                <div>
                                    Selecteer een begintijd:
                                    <Input name="startTime" value = {singleShift.startTime} onChange = {(e) => handleChangeShift(e, index)} type ='time'/>
                                </div>
                                <div>
                                    Selecteer een eindtijd:
                                    <Input name="endTime" value = {singleShift.endTime} onChange = {(e) => handleChangeShift(e, index)} type ='time'/>                
                                </div>
            
                                <BoxForSpacing/> 
                                <h4>Diploma's en certificaten:</h4>
                                                        
                                    <select name='selectedCertificate' onChange={(e) => handleChangeSelectedCertificate(e, index)}>
                                        {certificateTypes.map(certificateType => (
                                        <option value={JSON.stringify(certificateType)}>
                                            {certificateType.name}
                                        </option>
                                        ))}
                                    </select>                             
                              <BlueButton type ='button' fontSize={'3vw'} height={"22px"} onClick={()=> addCertificate(index)}>Certificaat toevoegen</BlueButton>
                                    <div>
                                        <table>
                                            <tbody>
                                                <tr><th>Toegevoegde certificaten:</th></tr>
                                            
                                                    {                                                       
                                                       shiftList[index].addedCertificates.map(certificate => (
                                                        <tr><td> {certificate.name}</td></tr> 
                                                       ))
                                                    }
                                            </tbody>
                                        </table>    
                                    </div>     
                                <BoxForSpacing/>
                            </div>
                        </div>
                        ))}
                        <BlueButton type ='button' fontSize={"14px"} height={"30px"} onClick={addShift}>Nog een dienst toevoegen</BlueButton>

                        <h4>
                            Vergoeding:  
                        </h4>
                        <div>                               
                            € <Salary value = {revenue} onChange = {(e) => setRevenue(e.target.value)} placeholder= "-" size="20"/> Per uur 
                        </div>                                 
                                    
                        <BoxForSpacing/>
                        <h4>
                            De opdracht omschrijving:
                        </h4>
                        <BigInput value = {description} onChange = {(e) => setDescription(e.target.value)} placeholder= '...'/> 
                        
                        <BoxForSpacing/>
                        <Button>Opdracht aanmaken</Button>                                               
                    </Form>           
                </Wrapper>
            </FormWrapper>    
            </Container>
        </BlueBackground>
        }
    </div>      
  )
}