import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import store from './redux/reducers/rootReducer';
import { Provider } from 'react-redux';
import axios from 'axios';

axios.defaults.baseURL = 'https://proxy.brandwacht.app/'
//axios.defaults.baseURL = 'http://localhost:8000/'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>      
      <App/>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);