import styled from 'styled-components';
import store from '../../../redux/reducers/rootReducer';
import { company } from '../../../redux/actions/selectCompany.action';
const Container = styled.div`
  margin: 5px;
  padding: 20px;
  width: 400px;
  height: 200px;
  background-color: white;
  border-radius: 10px;
  //overflow: hidden;
  cursor: pointer;
  font-size: 30px;  
`

export default function Job({item}) { 
  const handleClick = () =>{
    //set the selected companyId in redux
    store.dispatch(company(item.id)); 
  } 
  
  return (
    <Container onClick={handleClick}>      
      <div><h2>{item.name}</h2></div>         
    </Container>
  )
}
