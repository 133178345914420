import { Container } from '../../../styledComponents/globalStyles'
import styled from 'styled-components' ;
import { Colors }  from '../../../styledComponents/colors'

const Box = styled.div`
  height: 500px;
	background-color: ${Colors.JbgBlue};
  
`;

const Title = styled.label`
  font-weight: bold;
  color: white; 
  font-size : 40px;
`;

const Content = () => {
  return (
    <Box>
      <Container>
        <Title>   
          Een brandwacht voor al uw opdrachten
        </Title>
      </Container> 
    </Box>
  )
}
export default Content;



