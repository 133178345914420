import React, { useState, useEffect } from 'react';
import { getNotFullyVerifiedBrandwachten } from "../../services/binnendienst.service";
import styled from 'styled-components'
import {
  BlueBackground,
  Container,
  WhiteWrapper
} from '../../styledComponents/globalStyles'
import { useNavigate } from 'react-router-dom';
import {BlueButton} from "../../styledComponents/button.styles";
import {ResponsiveTableDiv} from "../../styledComponents/form.styles";

const Table = styled.table`
  
  border: 1px solid;
  color: black;
  width: 80%;
`
const Th = styled.th`
  text-align: left;
`

const DataToRight = styled.td`
  float: right;
`

const OverviewNotVerifiedBrandwachten = () => {
  const [data, setData] = useState([]); 
  const [noUsersFound, setNoUsersFound] = useState(false);
  useEffect(() => {
    getData();
  }, []);  
  
  const getData = async () => {
    const response = await getNotFullyVerifiedBrandwachten();
    if(response.data.status === 200)
    {
      if(response.data.users.length === 0){
        setNoUsersFound(true);
      }
      else {
        setData(response.data.users);
      }
    }     
  }

  return (
    <BlueBackground>
      <Container>
      <WhiteWrapper>
        <h1>De volgende brandwachten hebben verificatie nodig</h1>
        {noUsersFound?
            <ResponsiveTableDiv>
              <Table>
                <tr>
                  <Th>
                    Naam
                  </Th>
                </tr>
                {data.map(item => (
                    <TableRow user={item} key={item.id}/>
                ))}
              </Table>
            </ResponsiveTableDiv>
            :
            <h2>
              --
            </h2>
        }
      </WhiteWrapper>
      </Container>      
    </BlueBackground>
  )
}


const TableRow = ({user}) => {
  const navigate = useNavigate();  

  const handleClick = async () => {
    navigate('/expandNietGeverifieerdeBrandwachten', {state: user} );  
  }

  return (
    <tr>
      <td>
        {user.firstName + ' ' + user.lastName}
      </td>
      <DataToRight>
        <BlueButton fontSize={"13px"} height={"21px"} width={"130px"} onClick={handleClick}>
          Meer informatie
        </BlueButton>
      </DataToRight>           
    </tr>
  );
}
export default OverviewNotVerifiedBrandwachten