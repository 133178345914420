import {PaginationButton} from "../../../styledComponents/button.styles";
import scrollToTop from "../../../hooks/scrollToTop";

const Pagination = ({totalJobs, jobsPerPage, setCurrentPage, currentPage}) => {
  let pages = [];

  for(let i = 1; i <= Math.ceil(totalJobs/jobsPerPage); i++) {
    pages.push(i);
  }
  return(
    <div>
      {
        pages.map((page, index) => {
          return (<PaginationButton key={index} onClick={() => {setCurrentPage(page); scrollToTop();}} className={page == currentPage? 'active' : ''}>
            {page}
          </PaginationButton>);
        })
      }
    </div>
  )
}
export default Pagination;