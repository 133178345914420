import styled from "styled-components"

const Title = styled.h1`
  color: white;
`
const Text = styled.p`
  color: white;
`
const Link = styled.a`
  color: white;
`

export default function CompanyAdminCreatedHints() {
    return (
      <div>
        <Title>
            Welkom!
        </Title>
        <Text>
            Met u account kunt u nu de volgende stappen ondernemen:<br/>
            1. Maak een bedrijf aan onder het kopje "Bedrijven". Of klik <Link href='https://brandwacht.app/bedrijf-aanmaken'> hier</Link><br/>
            2. Plaats een opdracht onder het kopje "Opdracht toevoegen". Of klik <Link href='https://brandwacht.app/opdracht-toevoegen'> hier</Link><br/>
        </Text>          
     </div>
    )
  }