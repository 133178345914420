import styled from 'styled-components'
import { useState, useEffect } from 'react';
import { getMyShiftsWithJobs} from "../../services/jobs.service";
import { BlueBackground, Container} from '../../styledComponents/globalStyles';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import EuroSymbolOutlinedIcon from '@mui/icons-material/EuroSymbolOutlined';

const Titel = styled.h1`
  color: white;
`
const LocationDate = styled.div`
  display: flex;
  justify-content: space-between;
`
const Card = styled.div`
  margin: 50px;
  padding: 20px;
  width: 700px;
  height: 400px;
  background-color: white;
  border-radius: 10px;
  cursor: pointer;
  font-size: 30px;
  position  : relative ;
`
const MyJobs = () => {
  const [shifts, setShifts] = useState([]) 
  const [NoJobsfound, setNoJobsFound] = useState(false);

  useEffect(() => {
    getData();
  }, []);  
  
  const getData = async () => {
    const response = await getMyShiftsWithJobs();    
    if(response.data.status === 200)
    {
      if(response.data.shifts.length === 0){
        setNoJobsFound(true);
      }
      else
      {
        setShifts(response.data.shifts);
      }   
    }     
  }     
  
  return (
    <div>
      {NoJobsfound? <NoJobs/> : <RenderJobs shifts ={shifts}/>}
    </div>
  )
}

const NoJobs = () => {
  return(
    <BlueBackground>
      <Container>
        <Titel>U heeft nog geen opdrachten aangenomen</Titel>
      </Container>
    </BlueBackground>
  ) 
}

const RenderJobs = ({shifts}) => {
  return(
    <BlueBackground>
      <Container>
        <Titel>Uw opdrachten</Titel>      
        {shifts.map(item=>(
          <Shifts shift = {item} key = {item.id}/>
        ))}
      </Container>
    </BlueBackground>
  )  
}

const Shifts = ({shift}) =>{
  return(
    <Card>
      <div>
        <h2>
          {shift.job.title}
        </h2>
      </div>
      <LocationDate>
        <div>
          <LocationOnOutlinedIcon />
          {shift.job.city + ' ' + shift.job.address + ' ' + shift.job.zipcode}
        </div>
        </LocationDate> 
        <LocationDate>  
        <div>
          <CalendarMonthOutlinedIcon/>
          {
            shift.date
          }
        </div> 
          <div>
            <AccessTimeOutlinedIcon/>
            {
              shift.startTime.slice(0, -3)}-{shift.endTime.slice(0,-3)
            }
          </div>
        </LocationDate>
        <div>
          
        </div>
        <div>
          <EuroSymbolOutlinedIcon/>{shift.job.salary} per uur
        </div>                    
        <div>
          Beschrijving:
          {shift.job.description} 
        </div>
    </Card>
  )
} 
export default MyJobs