import axiosInstance from "./axiosInstance";

export async function registerCertificate(certificateType_id, expiryDate) {
    const response = await axiosInstance.post('api/registerCertificate',{certificateType_id: certificateType_id, expiryDate: expiryDate});
    return response;
}

export async function registerCertificateByUser_id(user_id, certificateType_id, expiryDate) {
    const response = await axiosInstance.post('api/registerCertificateByUser_id',{user_id: user_id, certificateType_id: certificateType_id, expiryDate: expiryDate});
    return response;
}

export async function createCertificateType(name) {
    let resp;
    await axiosInstance.post('api/createCertificateType',{name: name})
    .then((response) => {        
        resp = response;
    })
    .catch((error) => {        
        resp = error.response;
    });
    return resp; 
};

export async function getCertificateTypes() {
    const response = await axiosInstance.get('api/getCertificateTypes');
    return response.data;
}

export async function getMyCertificates() {
    const response = await axiosInstance.get('api/getMyCertificates');
    return response.data;
}

export async function getCertificatesSpecificUser(user_id) {
    const response = await axiosInstance.post('api/getCertificatesSpecificUser', {user_id: user_id});
    return response.data;
}

export async function verifyCertificate(certificate_id) {
    const response = await axiosInstance.post('api/verifyCertificate', {certificate_id: certificate_id});
    return response;
}

export async function getCertificatesExpiringInMonth() {
    const response = await axiosInstance.get('api/getCertificatesExpiringInMonth');
    return response;
}

