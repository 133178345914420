import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Login from "./pages/login/Login";
import WaitForVerification from "./pages/landing/landingHints/WaitForVerification";
import authService from "./services/auth.service";

const UseAuth = () => {
    return useSelector((state) => state.login.isLoggedIn);
}

const CheckIfBrandwachtVerified = () => {
    const roles = useSelector(state => state.roles);
    const isBrandwacht = authService.checkIfUserHasRole('brandwacht', roles);
    const isVerifiedBrandwacht = useSelector(state => state.login.isVerified);
    //console.log("brandwacht"+ isBrandwacht +"  verified" + isVerifiedBrandwacht) 
    if(isBrandwacht){
        if(isVerifiedBrandwacht) return true;
        return false;
    }
    //If not a brandwacht at all
    return true;
}

const ProtectedRoutes = () => {
    //Check if a brandwacht is verified because if not he should not be able to do anything till after verification
    const isVerified = CheckIfBrandwachtVerified();
    const isAuth = UseAuth();    
    
    if(isVerified)
    {        
        return isAuth ? <Outlet/> : <Login/>
    }
    return <WaitForVerification/>
};
export default ProtectedRoutes;
