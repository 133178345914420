import { BlueBackground, Container } from '../../styledComponents/globalStyles'
import Certificates from '../../components/common/Certificates'

export default function OverviewCertificates() {
  return (
   <BlueBackground>
     <Container>
        <Certificates/>
    </Container> 
   </BlueBackground>   
  )
}


