import styled from 'styled-components'
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Container,
  BlueBackground,
  MenuBox
} from '../../../styledComponents/globalStyles';

const ManageCompany = () => { 
    const navigate = useNavigate();
    const location = useLocation();
    
    const handleClick = async () => {
        
        navigate('/bedrijfsbeheer/personeel', { state: location.state})        
    }

  return (
    <BlueBackground>
      <Container>      
          <h1 style={{color: "white"}}>Bedrijfsbeheer</h1>
          <MenuBox onClick={handleClick}>
              Personeel
          </MenuBox>
      </Container>
    </BlueBackground>
  )
}
export default ManageCompany