import styled from 'styled-components'
import { useNavigate } from 'react-router-dom';
import {GrayButton} from "../../styledComponents/button.styles";

const ListItem = styled.li`
  border-style: groove;
  margin: 7px 0;
  line-height: 35px;
`

const Admin =({item}) => {
  const navigate = useNavigate();   
  const handleClick =  () => {
    navigate('/AdminPanel/EditAdmin', { state: item  });
  }

  return (
   <ListItem>
    Naam: {item.firstName + ' ' + item.lastName + ' '}    
      <GrayButton style={{float: "right", textAlign: "center"}}  height={"20px"} width={"100px"} onClick={handleClick}>
        Bewerken
      </GrayButton>
  </ListItem>   
  )
}
export default Admin;