import Filter from './Filter'
import styled from 'styled-components'
import React, { useState, useEffect } from 'react';
import {getOpenJobs} from '../../../services/jobs.service'
import { Container, BlueBackground } from '../../../styledComponents/globalStyles';
import JobCard from '../../../components/jobCard/JobCard.jsx';
import Pagination from "./Pagination";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;    
`
const ListWrapper = styled.div`  
  display: flex;
  flex-direction: column;    
`
const Titel = styled.h1`  color: white;`


export default function JobOverview() {
  const [openJobs, setOpenJobs] = useState([]);

  useEffect(() => {
    getData();
  }, []); 
  
  const getData = async () => {
    const response = await getOpenJobs();
    if(response.data.status === 200)
    {
      setOpenJobs(response.data.jobs);         
    }     
  }

  const [currentPage, setCurrentPage] = useState(1);
  const [jobsPerPage, setJobsPerPage] = useState(4);
  const lastJobIndex = currentPage * jobsPerPage;
  const firstJobIndex = lastJobIndex - jobsPerPage;
  const currentJobs = openJobs.slice(firstJobIndex, lastJobIndex);
  const isMobile = window.screen.width < 772;
  return (
    <BlueBackground>
      <Container>
        <Titel>Openstaande opdrachten</Titel>
          <Wrapper>
            {isMobile? null : <Filter/>}
                <ListWrapper>
                {openJobs && currentJobs.map((item)=>(
                  <JobCard item={item}/>
                ))}
                  <Pagination
                      totalJobs={openJobs.length}
                      jobsPerPage={jobsPerPage}
                      setCurrentPage={setCurrentPage}
                      currentPage={currentPage}
                  />
              </ListWrapper>
          </Wrapper>
      </Container>
    </BlueBackground>
  )  
}


