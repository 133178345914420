import React, { useState, useEffect} from 'react';
import { getCertificatesExpiringInMonth } from '../../services/certificate.service';
import {
    Container,
    BlueBackground,
    WhiteWrapper
} from '../../styledComponents/globalStyles';
import styled from 'styled-components';
import {ResponsiveTableDiv} from "../../styledComponents/form.styles";

const Table = styled.table`
  border-spacing: 50px 0;
  border: 1px solid;
  color: black;
`

const ExpiringCertificates = () => {
    const [certificates, setCertificates] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const getExipringCertificates = async () => {        
        const response = await getCertificatesExpiringInMonth();
        if(response.data.status === 200)
        {
          setCertificates(response.data.certificates);          
        }     
    }

    useEffect(() => {       
        getExipringCertificates();
        setIsLoading(false);
    },[]);

    if(isLoading)
    {
        return (
            <div>
                De pagina wordt geladen...
            </div>
        )
    }

    return (
        <BlueBackground>
            <Container>
                <WhiteWrapper>
                <h2>
                    Certificaten die binnen een maand verlopen
                </h2>
                <ResponsiveTableDiv>
                    <Table>
                        {certificates.length?
                            <tr>
                                <th>Naam van brandwacht</th>
                                <th>Certificaat</th>
                                <th>Verloop datum</th>
                            </tr>
                            :
                            null
                        }
                        {certificates.length? certificates.map(item=>(
                            <tr>
                                <td>
                                    {item.user.firstName + ' ' + item.user.lastName}
                                </td>
                                <td>
                                    {item.name}
                                </td>
                                <td>
                                    {item.expiryDate}
                                </td>
                            </tr>
                        ))
                        :
                        <h3>
                            Er zijn geen certificaten die aankomende maand verlopen
                        </h3>
                        }
                    </Table>
                </ResponsiveTableDiv>
                </WhiteWrapper>
            </Container>
        </BlueBackground>
    )
}

export default ExpiringCertificates;