import SignedOutLinks from './SignedOutLinks' 
import BrandwachtLinks from './BrandwachtLinks';
import CompanyAdminLinks from './CompanyAdminLinks';
import SignOutOnly from './SignOutOnly';
import { useSelector } from 'react-redux';
import authService from '../../services/auth.service';
import OfficeStaff from './OfficeStaff';
import React, { useState } from 'react';
import {Nav, Logo, Menu, Hamburger, HamburgerSpan, MobileMenu, MenuWrapper} from "../../styledComponents/navbar.styles"
import { Container } from '../../styledComponents/globalStyles'

const Navbar = () => {
  const [showMobileNavbar, setShowMobileNavbar] = useState(false);
  
  const roles = useSelector(state => state.roles);
  const isLoggedIn = useSelector(state => state.login.isLoggedIn);
  const isBrandwacht = authService.checkIfUserHasRole('brandwacht', roles);
  const isOfficeStaff = authService.checkIfUserHasRole('binnendienst', roles);
  const isCompanyAdmin = authService.checkIfUserHasRole('bedrijfsbeheerder', roles);
  
  const isVerified = useSelector(state => state.login.isVerified);
    function links() 
    {
      if(!isLoggedIn) return <SignedOutLinks/>                         
      if(isOfficeStaff) return <OfficeStaff/>      
      if(isBrandwacht && isVerified) return <BrandwachtLinks/>     
      if(isBrandwacht && !isVerified) return <SignOutOnly/>     
      if(isCompanyAdmin) return <CompanyAdminLinks/>   
      return <SignedOutLinks/>   
    }

  return (
    <Container>
      <Nav>        
          <Logo src='/assets/JBGSafetyLogo.png' /> 
          <MenuWrapper>
            <Menu>
              { links() }
            </Menu> 
          </MenuWrapper>

          <Hamburger onClick={() => setShowMobileNavbar(!showMobileNavbar)}>              
            <HamburgerSpan></HamburgerSpan>
            <HamburgerSpan></HamburgerSpan>
            <HamburgerSpan></HamburgerSpan>
          </Hamburger>    
      </Nav>
      {showMobileNavbar? (<MobileMenu> { links() } </MobileMenu>) : null}      
  </Container> 
  )
}
export default Navbar


