import styled from 'styled-components'
import { useState, useEffect, useRef } from 'react';
import { registerCertificate, getMyCertificates, getCertificateTypes } from '../../services/certificate.service';
import {GrayButton} from "../../styledComponents/button.styles";
import {ErrorMessage} from "../../styledComponents/form.styles";

const Table = styled.table`
  border-spacing: 3vw 0;
  border: 1px solid;
  color: white;
`
const Title = styled.h2`
    color: white; 
`
const Container = styled.div`
    color: white; 
`
export default function OverviewCertificates() {
  const [expiryDate, setExpiryDate] = useState('');
  const [myCertificates, setMyCertificates] = useState([]); 
  const [showRegisterCertificate, setShowRegisterCertificate] = useState(false); 
  const [certificateTypes, setCertificateTypes] = useState([]);
  const [localMyCertificates, setlocaMyCertificates] = useState([]);
  //Selected certificateType, with the first one as default
  const [selectedCertificateTypeId, setSelectedCertificateTypeId] = useState('');
  //Error states
  const [certificateAlreadyExists, setCertificateAlreadyExists] = useState(false);
  const errorCertificateAlreadyExists = "Dit certificaat is al door u geregistreerd";

  useEffect(() => {         
    getDataCertificates();
    getDataCertificateTypes();
  },[localMyCertificates]);
  
  const isFirstRender = useRef(true);
  useEffect(() => { 
    if(isFirstRender.current)
    {
        isFirstRender.current = false;
        return;
    }        
    setSelectedCertificateTypeId(certificateTypes[0].id);    
   }, [certificateTypes]) 

  const getDataCertificates = async () => {
    const response = await getMyCertificates();
    if(response.status === 200)
    {
      setMyCertificates(response.certificates);         
    }     
  }
  
  const getDataCertificateTypes = async () => {
    const response = await getCertificateTypes();
    if(response.status === 200)
    {
      setCertificateTypes(response.certificateTypes);
      
    }     
  } 
  
  const registerCertificateHandler = async () => {         
    setCertificateAlreadyExists(false);
    const response = await registerCertificate(selectedCertificateTypeId, expiryDate);

    if(response.data.status === 200){
      //trigger useEffect                      
      setlocaMyCertificates(selectedCertificateTypeId);
    }
    else
    {
      if(response.data.status === 422) {
          setCertificateAlreadyExists(true);
      }
    }
  }
  
  const ShowRegisterCertificate = () => {
      setShowRegisterCertificate(true);
  }

  const handleChange = (event) => {
    setSelectedCertificateTypeId(event.target.value);            
  };

  return (
    <Container>
        <Title>
            Uw bij ons bekende certificaten
        </Title>
        <Table>
            <tr>
                <th>Certificaat</th>
                <th>Verloop datum</th>
                <th>Geverifieerd</th>
            </tr>
            {myCertificates.map(item=>(
                  <TableRow certificate = {item} key = {item.id}/>
                ))}
        </Table>
        <GrayButton height={"25px"} width={"200px"} onClick={ShowRegisterCertificate}>
          Certificaat toevoegen
        </GrayButton>

        {showRegisterCertificate? 
          <div>
            Naam van certificaat:
            <select onChange={handleChange}>
              {certificateTypes.map(certificateType => (
                <option value={certificateType.id}>
                  {certificateType.name}
                </option>
              ))}
            </select>
            Verloopdatum
            <input value = {expiryDate} onChange = {(e) => setExpiryDate(e.target.value)} type ='date'/>
            <GrayButton fontSize={"14"} height={"20px"} width={"100px"} onClick={registerCertificateHandler}>Registreren</GrayButton>
          </div>
          : null}
      {certificateAlreadyExists?
          <ErrorMessage>{errorCertificateAlreadyExists}</ErrorMessage>
          : null
      }
    </Container>
  )
}

const TableRow = ({certificate}) => {
  return (
    <tr>
      <td>
        {certificate.name}
      </td>
      <td>
        {certificate.expiryDate}
      </td>
      <td>
        {certificate.verified? 'Ja': 'Nee' }
      </td>
    </tr>
  );
}


