import styled from 'styled-components'
import { Colors } from './colors';

export const Form = styled.form`
    display: flex;
    flex-direction: column;
`
export const Title = styled.h1`
    text-align: center;
`
export const Input = styled.input`
    flex: 1;
    min-width: ${props => props.minWidth || '40%'};
    max-Width: ${props => props.maxWidth};
    margin: 10px 0px;
    padding: 10px;
    border-radius: 10px;
`

export const Button = styled.button`
    width: 100%;
    height: 40px;
    border: none;
    padding: 15px, 20px;
    background-color : ${Colors.JbgBlue};
    color: white;
    font-weight: bold;
    font-size: 22px;
    cursor: pointer;
    border-radius: 25px;
    justify-content: center;
`
export const ButtonWrapper = styled.div`
    text-align: center;
`

export const ErrorMessage = styled.div`
    color: red;
    text-align: center;
`

export const FormWrapper = styled.div`
    padding: 20px;
    width: 40%;
    background-color: white;
    border-radius: 10px;
`

export const ResponsiveTableDiv = styled.div`
  overflow-x:auto;
`