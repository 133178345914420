import styled from 'styled-components'
import React, { useState, useEffect, useRef } from 'react';
import { getFulfilledJobsByCompanyId, getOpenJobsByCompanyId } from "../../services/jobs.service";
import {
  BlueBackground,
  Container,
  WhiteWrapper
} from '../../styledComponents/globalStyles';
import { getMyCompanies } from '../../services/companies.service';
import NoCompaniesFound from '../../components/common/NoCompaniesFound';
import {BlueButton} from "../../styledComponents/button.styles";
import {ResponsiveTableDiv} from "../../styledComponents/form.styles";

const TD = styled.td`
  text-align: center;
  
`
const Table = styled.table`
  border-spacing: 10vw 0;
  border: 1px solid;
  color: black;
`

const CompanyJobs = () => {
  const [showOpenJobs, setShowOpenJobs] = useState(true);
  const [showFulfilledJobs, setShowFulfilledJobs] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState('');
  const [companies, setCompanies] = useState([]);
  const [noCompanyFound, setNoCompanyFound] = useState(true);

  const isFirstRender = useRef(true);
  useEffect(() => { 
    if(isFirstRender.current)
    {
      getCompanies();    
      isFirstRender.current = false;
      return;
    }        
      setSelectedCompanyId(companies[0].id);
  }, [companies])

  const getCompanies = async () => {
    const response = await getMyCompanies();
    
    if(response.data.status === 200)
    {            
        if(response.data.companies.length === 0)
        {
            setNoCompanyFound(true);
        }
        else
        {
            setCompanies(response.data.companies); 
            setNoCompanyFound(false);                                          
        }                                         
    }
}

  const setButtonOpenJobs = () =>{
    setShowOpenJobs(true);
    setShowFulfilledJobs(false);
  }
  const setButtonFulfilledJobs = () =>{
    setShowOpenJobs(false);
    setShowFulfilledJobs(true);    
  }
  const handleChangeCompany = (event) => {
    setSelectedCompanyId(event.target.value);        
  };
  return (
    <div>
      {noCompanyFound? <NoCompaniesFound/>
        :
    <BlueBackground>
      <Container>
        <WhiteWrapper>
        <h1>Hieronder staan de opdrachten van je bedrijf</h1>
        <div>
          Selecteer je bedrijf: 
          <select onChange={handleChangeCompany}>
            {companies.map(company => (
            <option value={company.id}>
              {company.name}
            </option>
          ))}
          </select>                            
        </div>
        <BlueButton heigth={"35px"} width={"200px"} fontSize={"15px"} onClick={setButtonOpenJobs}>Openstaande opdrachten</BlueButton>
        <BlueButton heigth={"35px"} width={"200px"} fontSize={"15px"
            + ""} onClick={setButtonFulfilledJobs}>Vervulde opdrachten</BlueButton>
        <RenderJobs id={selectedCompanyId} showOpen= {showOpenJobs} showFulfilled= {showFulfilledJobs}/>
        </WhiteWrapper>
      </Container>
    </BlueBackground>
    }
    </div> 
  )
}
export default CompanyJobs

const RenderJobs = (props) => {
  const [jobs, setJobs] = useState([]); 
  let showOpenJobs = props.showOpen;
  let showFulfilledJobs = props.showFulfilled;

  useEffect(() => {
    if(showOpenJobs)
    { 
      getDataOpenJobs();
    }
    else if(showFulfilledJobs)
    {
      getDataFulfilledJobs()
    }
  }, [showOpenJobs, showFulfilledJobs]) 

    
  const getDataOpenJobs = async () => {
    const response = await getOpenJobsByCompanyId(props.id);
    if(response.data.status === 200)
    {
      setJobs(response.data.jobs);      
    }     
  }

  const getDataFulfilledJobs = async () => {
    const response = await getFulfilledJobsByCompanyId(props.id);
    if(response.data.status === 200)
    {
      setJobs(response.data.jobs);      
    }     
  }
  return (
    <ResponsiveTableDiv>
      {jobs.map(item=>(
        <Table>
          <thead>
            <tr>
              <th>
                Naam van de opdracht
              </th>
              <th>
                Aangemaakt op
              </th>
              <th>
                Plaats en adres
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <TD>
                {item.title}
              </TD>
              <TD>
                {item.created_at.slice(0, -17)}
              </TD>
              <TD>
                {item.city + ' ' + item.address}
              </TD>
            </tr>
            <h4>Diensten:</h4>
          </tbody>
            {item.shifts.map(shift => (
              <tbody>
              <tr>
                <th>
                  Datum
                </th>
                <th>
                  Begin tijd - eind tijd
                </th>
                <th>
                  Brandwacht
                </th>
              </tr>
              <tr>
                <TD>
                  {shift.date}
                </TD>
                <TD>
                  {shift.startTime  + ' -  ' + shift.endTime}
                </TD>
                <TD>
                  {shift.user? shift.user.firstName + ' ' + shift.user.lastName : 'Nog geen brandwacht ingepland'}
                </TD>
              </tr>
              </tbody>
            ))}                        
        </Table>
      ))}
    </ResponsiveTableDiv>
  )
}