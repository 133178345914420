import store from '../../redux/reducers/rootReducer'
import { login } from '../../redux/actions/login.action';
import authService from '../../services/auth.service';
import {MenuItem, MenuItemWrapper} from "../../styledComponents/navbar.styles"

export default function CompanyAdminLinks() {

  const handleClick = async (e) => {
    store.dispatch(login(false)); 
    authService.logout();       
  };
  return (    
      <MenuItemWrapper>
        <li>
          <span>
            <MenuItem to='/companyjobs'>Aangemaakte opdrachten </MenuItem>
          </span>
        </li>        
        <li>
          <span>
          <MenuItem to='/opdracht-toevoegen'>Opdracht toevoegen</MenuItem>
          </span>
        </li>
        <li>
          <span>
          <MenuItem to='/bedrijven'>Bedrijven </MenuItem>
          </span>
        </li>         
        <li>
          <span>
          <MenuItem  onClick={handleClick} to='/'>Uitloggen </MenuItem>
          </span>
        </li>
        <li>
          <span>
          <MenuItem  to='/profile'> Account </MenuItem>
          </span>
        </li>         
      </MenuItemWrapper>
    
  )
}
