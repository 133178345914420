import { useEffect } from "react";
import { getAllJobs } from "../../../services/jobs.service";
import { useState } from "react";
import {
    BlueBackground,
    Container,
    WhiteWrapper
} from "../../../styledComponents/globalStyles";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {BlueButton} from "../../../styledComponents/button.styles";

const Table = styled.table`
  border-spacing: 50px 0;
  border: 1px solid;
  color: black;
`

const JobsToEdit = () =>{
    const [jobs, setJobs] = useState([]);

    const fetchAllJobs = async() =>{
        const response = await getAllJobs();
        if(response.status === 200){
            setJobs(response.data.jobs);
        }
    }

    useEffect(() => {
        fetchAllJobs();
    }, []);    

    return (
        <BlueBackground>
            <Container>
            <WhiteWrapper>
                <Table>
                {jobs.length?
                    <tr>
                        <th>
                            Bedrijfsnaam
                        </th>
                        <th>
                            Titel
                        </th>
                        <th>
                            Datum van aanvraag
                        </th>
                    </tr>
                :
                null
                    }

                {jobs.length? jobs.map(item=>(                            
                        <tr>
                            <td>
                                {item.company_id}
                            </td>
                            <td>
                                {item.title}
                            </td>
                            <td>
                                {item.created_at.slice(0, -17)}
                            </td>
                            <Link to='EditJob' state={item}>
                                <BlueButton fontSize={"13px"} height={"20px"} width={"100px"}>
                                    Aanpassen
                                </BlueButton>
                            </Link>                           
                        </tr>
                    ))
                    :
                    <h3>
                        Er zijn geen opdrachten gevonden... 
                    </h3>
                    }   
                </Table>
            </WhiteWrapper>
            </Container>
        </BlueBackground>
    );
}
export default JobsToEdit