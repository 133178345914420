const initState = {
    roles: [],      
  }
  
  const rolesReducer = (state = initState, action) => {
      switch(action.type){
          case 'SETROLES':
              return {...state, roles:action.payload}          
          default: return state;
      }
  }
  export default rolesReducer
  
  
  