import styled from 'styled-components';
import React, { useState } from 'react';
import AuthService from '../../services/auth.service';
import { Button, ButtonWrapper, ErrorMessage } from '../../styledComponents/form.styles';
const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
`
const Wrapper = styled.div`
    padding: 20px;
    width: 30%;
    background-color: white;
    border-radius: 10px;
`
const Form = styled.form`
    display: flex;
    flex-direction: column;
`
const Title = styled.h1`
    text-align: center;
`
const Input = styled.input`
    flex: 1;
    min-width: 40%;
    margin: 10px 0px;
    padding: 10px;
    border-radius: 10px;
`
const ForgotPassword = () => {
    const [email, setEmail] = useState('');  
    const [showPasswordResetMailSend, setPasswordResetMailSend] = useState(false);
    const passwordResetMailSend = 'We hebben u een wachtwoord herstel e-mail gestuurd'; 
    //Error messages
    const fieldRequired  = 'Dit veld is verplicht';     
    //Error states
    const [showEmptyEmail, setEmptyEmail] = useState(false);    
    
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(!validateInput()) return;

        await AuthService.forgotPassword(email);
        setPasswordResetMailSend(true);            
    }
    
    const validateInput = () =>{
        let valid = true;
        //Set all the states to false, to revalidate
        setEmptyEmail(false);       

        //Validate the inputs
        if(email.length < 3){ setEmptyEmail(true); valid = false;}     
              
        return valid;
    }
    
  return (          
    <Container>
        <Wrapper>
            <Title>Wachtwoord vergeten</Title>
            <Form onSubmit={handleSubmit}>
                <Input value = {email} onChange = {(e) => setEmail(e.target.value)} placeholder= 'Email'/>
                <ErrorMessage>{showEmptyEmail?  fieldRequired : null}</ErrorMessage>             
                       
                <ButtonWrapper>
                    <Button>Stuur mij een wachtwoord herstel mail</Button>
                </ButtonWrapper>                
            </Form>
            {showPasswordResetMailSend? passwordResetMailSend : null}
        </Wrapper>
    </Container>    
  )
}
export default ForgotPassword;