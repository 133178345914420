import axiosInstance from "./axiosInstance";

export async function createJob(title, address, zipcode, city, shiftList, revenue, description, selectedCompanyId) {
    const job = {title, address, zipcode, city, shiftList, revenue, description, selectedCompanyId}       
    const response = await axiosInstance.post('api/add-job', job);
    return response;
}

export async function getNotPublishedJobs() {
    const response = await axiosInstance.get('api/getNotPublishedJobs');
    return response;
}

export async function getShiftsByJobId(job_id) {
    const response = await axiosInstance.post('api/getShiftsByJobId', {job_id: job_id});
    return response;
} 

export async function getOpenJobs() {
    const response = await axiosInstance.get('api/getJobsWithDistance');
    return response;
} 

export async function getMyShiftsWithJobs() {
    const response = await axiosInstance.get('api/getMyShiftsWithJobs');
    return response;
} 

export async function getOpenJobsByCompanyId(companyId) {
    const response = await axiosInstance.post('api/getOpenJobsByCompanyId', {id: companyId});
    return response;
} 

export async function getFulfilledJobsByCompanyId(companyId) {
    const response = await axiosInstance.post('api/getFulfilledJobsByCompanyId', {id: companyId});
    return response;
}

export async function getUsersOnJob(jobId) {
    const response = await axiosInstance.post('api/get-user-on-job', {id: jobId}); 
    return response;
} 

export async function claimJob(jobId) {
    const response = await axiosInstance.post('api/claim-job', {id: jobId}); 
    return response;
} 

export async function getRevenueByJobId(job_id) {
    const response = await axiosInstance.post('api/getRevenueByJobId', {job_id: job_id}); 
    return response;
}

export async function publishJob(job_id, salary) {
    const response = await axiosInstance.post('api/publishJob', {job_id: job_id, salary: salary}); 
    return response;
}

export async function getAllJobs() {
    const response = await axiosInstance.get('api/getAllJobs');
    return response;
}

export async function editJob(job_id, shifts) {
    const response = await axiosInstance.post('api/editJob', {job_id: job_id, shifts: shifts}); 
    return response;
}


