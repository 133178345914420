import styled from 'styled-components'
import React, { useState, useEffect } from 'react';
import { getMyCompanies} from '../../services/companies.service';
import { checkAvailabilityAllEployees } from '../../services/googleCalendar.service';
import AvailableBrandwacht from './AvailableBrandwacht';
import { Container, BlueBackground } from '../../styledComponents/globalStyles';
import { Button, ButtonWrapper } from '../../styledComponents/form.styles';
import NoCompaniesFound from '../../components/common/NoCompaniesFound';

const Box = styled.div`
    padding: 20px;
    width: 90%;
    background-color: white;
    border-radius: 10px; 
    margin: 0 auto;
`
const Wrapper = styled.div`
    padding-bottom: 20px;
`
const Form = styled.form`
    
`
const Title = styled.h1`

`
const Input = styled.input`
    flex: 1;
    min-width: 40%;
    margin: 20px 10px 0px 0px;
    padding: 10px;
    border-radius: 5px;
`


export default function FindAvailableEmployee() {
    const [noCompanyFound, setNoCompanyFound] = useState(true);
    const [companies, setCompanies] = useState([]);
    

    useEffect(() => { 
       getCompanies();       
      }, []) 
    
    const getCompanies = async () => {
        const response = await getMyCompanies();
        
        if(response.data.status === 200)
        {            
            if(response.data.companies.length === 0)
            {
                setNoCompanyFound(true);
            }
            else
            {
                setCompanies(response.data.companies); 
                setNoCompanyFound(false);                                          
            }                                         
        }
    }

  return (
    <div>        
        {noCompanyFound? <NoCompaniesFound/> : <FindAvailableEmployeesForm companies={companies}/>}
    </div>      
  )
}

const FindAvailableEmployeesForm = ({companies}) => {
    const [minTime, setStartTime] = useState('');
    const [maxTime, setEndTime] = useState('');    
    //Set the first companyID
    const [selectedCompanyId, setSelectedCompanyId] = useState(companies[0].id);
    const [availableEmployees, setAvailableEmployees] = useState([]);
     
    
    
    //Post to find available employees
    const handleSubmit = async (e) => {
        e.preventDefault();              
        const response = await checkAvailabilityAllEployees(minTime, maxTime, selectedCompanyId);
        

        if(response.data.status === 200)
        {           
            const newAvailableEmployees = response.data.availableEmployees;
            setAvailableEmployees([...newAvailableEmployees]);            
        }
    }

    const handleChange = (event) => {
        setSelectedCompanyId(event.target.value);        
      };

  return (      
    <BlueBackground>
        <Container>        
            <Box>
                <Form onSubmit={handleSubmit}>
                <Title>Vind beschikbare medewerkers</Title>
                <Wrapper>
                    Selecteer je bedrijf: 
                    <select onChange={handleChange}>
                        {companies.map(company => (
                            <option value={company.id}>{company.name}</option>
                    ))}
                    </select>  
                </Wrapper>            
                <Wrapper>
                    Beschikbaar vanaf:
                    <Input value = {minTime} onChange = {(e) => setStartTime(e.target.value)} type ='datetime-local'/>
                
                    tot:
                    <Input value = {maxTime} onChange = {(e) => setEndTime(e.target.value)} type ='datetime-local'/>                
                </Wrapper>
                
                <ButtonWrapper>
                    <Button>Zoek</Button> 
                </ButtonWrapper>                             
                </Form>       
                <AvailableBrandwacht availableEmployees={availableEmployees}/>
            </Box>    
        </Container>    
    </BlueBackground>
  )
}

