import axiosInstance from "./axiosInstance";


export async function getZzpByUser_id(user_id) {
    const response = await axiosInstance.post('api/getZzpByUser_id', {user_id: user_id});
    return response;
} 

export async function verifyZzp(zzp_id) {
    const response = await axiosInstance.post('api/verifyZzp', {zzp_id: zzp_id});
    return response;
} 


