import styled from 'styled-components'

const List = styled.ul`
    
    list-style-type: none; 
    background-color  : white;
`
const ListItem = styled.li`
  border-style: groove;
  margin: 7px 0;
  color: black;
`
export default function AvailableBrandwacht({availableEmployees}) {  

  return (
    <List>            
        {availableEmployees.map(employee=>(                 
            <ListItem>
                Naam: {employee.firstName + ' ' + employee.lastName}                   
            </ListItem>      
        ))}
    </List>
  )
}